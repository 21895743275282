/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/bookings/bookingsActions";
import axios from 'axios';
// import { useHistory } from "react-router";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_websoc/_partials/controls";
import { BookingCreateForm } from "./BookingCreateForm";
import { BeforeBooking } from "./BeforeBooking";
import { useSubheader } from "../../../../../../_websoc/layout";
import { ModalProgressBar } from "../../../../../../_websoc/_partials/controls";
import swal from "sweetalert";
import PaymentInfoDialog from "./PaymentInfoDialog";

const initBooking = {
  selectedCubicalObj: []
};

export function BookingCreate({
  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();
  // let history = useHistory()
  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const [showPaymentDialog, setPaymentDialog] = useState(false);

  const hatcheryDetails = useSelector((state) => state.dashboardData.hatcheryDetails, shallowEqual);


  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, bookingForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.bookings.actionsLoading,
      bookingForEdit: state.bookings.bookingForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchBooking(id));
  }, [id, dispatch]);



  useEffect(() => {
    let _title = id ? "" : "New Booking";
    if (bookingForEdit && id) {
      _title = `Edit Booking`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingForEdit, id]);

  const saveBooking = (values) => {
    if (!id) {

      axios.post('bookings', values)
        .then(response => {
          setPaymentDialog(true)
          // startPayment(response.data)
        })
        .catch((error) => {
          swal(error.response.data.message, "Failed", "error")
        })

    } else {
      dispatch(actions.updateBooking(values)).then(() => backToBookingsList());
    }
  };

  // Do not remove below commented method it may required in future 

  function startPayment(bookingResponse) {

    const converTedBalance = parseFloat(bookingResponse.totalPrice)
    let totalBalance = converTedBalance * 100

    var options = {
      "key": process.env.REACT_APP_RAZORPAY_KEY,
      "amount": totalBalance,
      "currency": "INR",
      "name": "RGCA",
      "description": "Booking Payment",
      "image": "https://rzp-mobile.s3.amazonaws.com/images/rzp.png",
      "order_id": bookingResponse.transaction.paymentId,
      "handler": function (response) {
        swal("Payment Success", response.razorpay_payment_id, "success");
      },
      "prefill": {
        "name": bookingResponse.hatcheryId.name,
        "email": bookingResponse.hatcheryId.email,
        "contact": bookingResponse.userProfile.phoneNumber,
        "customer_id": bookingResponse.hatcheryId.rzypayCustomerId,
        // "virtualAccountId": bookingResponse.hatcheryId.rzrpayVirtualAccId
      },
      "notes": {
        "address": "RGCA",
        "type": "BOOKING",
        "id": bookingResponse.id,
      },
      "theme": {
        "color": "#3399cc"
      }
    };

    let rzp1 = new window.Razorpay(options)
    rzp1.on('payment.failed', function (response) {
      console.log(bookingResponse)
      console.log(response.error.description)
    });
    rzp1.open()
  }


  const btnRef = useRef();
  const saveBookingClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToBookingsList = () => {
    history.push(`/Bookings/list/`);
  };


  const handleDialog = () => {
    setPaymentDialog(false)
    history.push('/dashboard')
  }

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToBookingsList}
            className="btn btn-light">
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {
            tab !== "basic" &&
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={saveBookingClick} >Pay Now</button>
          }
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab("basic")}>
            <a
              className={`nav-link ${tab === "basic" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "basic").toString()}>
              Booking info
            </a>
          </li>
        </ul>
        <div className="mt-5">
          {
            tab === "basic"
              ?
              <BeforeBooking
                setTab={setTab}
              />
              :
              <BookingCreateForm
                actionsLoading={actionsLoading}
                booking={bookingForEdit || initBooking}
                btnRef={btnRef}
                saveBooking={saveBooking}
              />
          }
        </div>
        <PaymentInfoDialog
          show={showPaymentDialog}
          hide={handleDialog}
          infoMessage={"Your booking has been blocked. Please transfer amount to the below account to confirm your booking"}
        />
      </CardBody>
    </Card>
  );
}
