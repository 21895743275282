import * as requestFromServer from "./bookingsCrud";
import {bookingsSlice, callTypes} from "./bookingsSlice";
import { Alert } from "react-bootstrap";
import swal from 'sweetalert';


const {actions} = bookingsSlice;

export const fetchBookings = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllBookings(queryParams)
    .then(response => {
      const  entities = response.data;
      const  totalCount = response.data.length;

      dispatch(actions.bookingsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find bookings";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSuppliers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllSuppliers(queryParams)
    .then(response => {
      const  entities = response.data;
      const  totalCount = response.data.length;

      dispatch(actions.suppliersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find bookings";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchPastBookings = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllPastBookings(queryParams)
    .then(response => {
      const  entities = response.data;
      const  totalCount = response.data.length;
      dispatch(actions.pastBookingsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find bookings";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const verifyBookingPayment = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .verifyBookingPayment(queryParams)
    .then(response => {
      const  entities = response.data;
      const  totalCount = response.data.length;
     // dispatch(actions.verifyBookingPayment({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Verify Payment Failed";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const fetchBookingByMonth = (params,data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findBookingByMonth(params,data)
    .then(response => {
      const bookingByMonthData = response.data;
      dispatch(actions.bookingByMonthFetched({ bookingByMonthData: bookingByMonthData }));
    })
    .catch(error => {
      error.clientMessage = "Can't find bookingByMonthData";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchBooking = id => dispatch => {
  if (!id) {
    return dispatch(actions.bookingFetched({ bookingForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBookingById(id)
    .then(response => {
      const booking = response.data;
      dispatch(actions.bookingFetched({ bookingForEdit: booking }));
    })
    .catch(error => {
      error.clientMessage = "Can't find booking";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const deleteBooking = (id,cancelRemark) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBooking(id,cancelRemark)
    .then(response => {
      swal("Booking Cancelled", "success");
      dispatch(actions.bookingDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete booking";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createBooking = bookingForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createBooking(bookingForCreation)
    .then(response => {
      // swal("Booking Created!", "success");
      const { booking } = response.data;
      dispatch(actions.bookingCreated({ booking }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create Booking";
      if(error.response)
      swal("Failed", error.response.data.message,"error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    })
};

export const updateBooking = booking => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBooking(booking)
    .then(() => {
      dispatch(actions.bookingUpdated({ booking }));
    })
    .catch(error => {
      error.clientMessage = "Can't update booking";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateAuthPerson = data => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAuthPerson(data)
    .then(() => {
      // dispatch(actions.bookingUpdated({ booking }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Auth person";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateShippingDetails = data => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateShippingDetails(data)
    .then(() => {
      // dispatch(actions.bookingUpdated({ booking }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Shipping Details";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBookingsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForBookings(ids, status)
    .then(() => {
      dispatch(actions.bookingsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update bookings status";
      Alert(error.clientMessage)
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBookings = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBookings(ids)
    .then(() => {
      dispatch(actions.bookingsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete bookings";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
