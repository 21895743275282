import axios from "axios";


export const BOOKINGS_URL = "/bookings";
export const REPORTS_URL = "/reports";

export const NOTIFICATIONS_URL = "/notification-statuses";
export const USERROLE_ACCESS = "auth/permissions";
export const BOOKINGS_COUNT_URL = "/bookings/count";
export const LAR_URL = "/lab-analysis-reports/count";
export const HATCHERIES_URL = "/transactions/count";
export const BOOKINGSVSSPECIES_URL = "/species-wise-imported";
export const GET_HATCHERY_DETAILS_URL = "/hatcheries";
export const TOTALVSOPENBOOKINGS_URL = "/bookings?status=Confirmed";
export const TOTALVSOPENLAR_URL = "/lab-analysis-reports?approvalFlag=true&bookingId.status=Confirmed";
export const TOTAL_BS_URL = "/hatcheries/bs-imported"
export const TERM_AND_CONDITION_URL = "settings?type=TERMS_CONDITIONS"



export function getHatcheryDetails(id) {
  return axios.get(`${GET_HATCHERY_DETAILS_URL}/${id}`);
}

export function getAllBookingsCount() {
  return axios.get(BOOKINGS_COUNT_URL);
}

export function getAllBookingsForTransactions() {
  return axios.get(`${BOOKINGS_URL}?status=Booked&&status=Blocked&&_limit=-1`);
}

export function getAllBSPerYear(year) {
  return axios.get(`${TOTAL_BS_URL}/${year}`);
}

export function getNotifications() {
  return axios.get(NOTIFICATIONS_URL);
}


export function getTermsAndConditions() {
  return axios.get(TERM_AND_CONDITION_URL);
}



export function getUserRolesAndAccess(id) {
  return axios.get(`${USERROLE_ACCESS}`);
}

export function getAllBookings() {
  return axios.get(BOOKINGS_URL);
}
export function getLARCount() {
  return axios.get(LAR_URL);
}
export function getHatcheriesCount() {
  return axios.get(HATCHERIES_URL);
}
export function getReports() {
  return axios.get(REPORTS_URL);
}
export function getTotalVsOpenBookings() {
  return axios.get(TOTALVSOPENBOOKINGS_URL);
}
export function getTotalVsOpenLAR() {
  return axios.get(TOTALVSOPENLAR_URL);
}


