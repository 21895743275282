import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { useHistory } from 'react-router-dom'
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import { shallowEqual, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Input, Select } from "../../../../_websoc/_partials/controls";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_websoc/_partials/controls";
import { toAbsoluteUrl } from "../../../../_websoc/_helpers";
import { Button } from "react-bootstrap";
import Webcam from "react-webcam";
import axios from 'axios'
const initialValues = {
};

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

function UserOnBoardingProcessStepOne(props) {

  const webcamRef = React.useRef(null);

  const [openWebCamera, setOpenWebCamera] = useState(false);

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log(imageSrc, "IMAGECAPTURED")
      setOpenWebCamera(false)
      let dataImage = imageSrc.toString().replace("\n", "")
      dataImage = dataImage.replace('data:image/jpeg;base64,', '');

      let payLoad = {
        "userid": "37",
        "livefaceimage": dataImage,
        "rrn": "RANDOM STRING",
        "reftimestamp": `${Math.floor(Date.now() / 1000)}`,
        "slk": "IVPAF-LSVMG-JMZCE-TXVEM",
        "servicecode": "23",
        "enrollmentid": "28888888"
      };

      axios.post('http://202.140.138.41:85/api/FaceAttendanceClientGateway/FaceEnroll', {
        payLoad
      })
        .then((response) => {
          console.log(response, "FACEAPI-SUCCESS");
        }, (error) => {
          console.log(error, "FACEAPI-ERROR");
        });
    },
    [webcamRef]
  );



  const { intl } = props;
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const { HatcheryDetails } = useSelector(
    (state) => ({
      // actionsLoading: state.auth.actionsLoading,
      HatcheryDetails: state.auth.HatcheryDetails,
    }),
    shallowEqual
  );
  console.log(HatcheryDetails)
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    HatcheryDetails,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      userOnBoardingProcess()
    },

  });

  const userOnBoardingProcess = () => {
    console.log(props.history, "()()()", props)
    props.history.push(`/user-onboarding-process/step-Final`);
  };

  function handleFaceClick() {
    setOpenWebCamera(true)
  }


  return (

    <Card>
      <CardHeader title="Hatchery Details">
        {/* <CardHeaderToolbar>
          <Button onClick={handleFaceClick}> Temp Face Scanning Here</Button>
        </CardHeaderToolbar> */}
      </CardHeader>
      <CardBody>
        <>

          <Formik
            enableReinitialize={true}
            initialValues={HatcheryDetails}
            onSubmit={(values) => {
              userOnBoardingProcess();
            }}
          >
            {({ handleSubmit }) => (
              <>
                <Form style={{ width: 500 }} className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        disabled
                        editable={false}
                        name="name"
                        component={Input}
                        placeholder=""
                        label="Hatchery Name"
                      />
                    </div>
                  </div>
                  <div className="form-group row">

                    <div className="col-lg-12">
                      <Field
                        disabled
                        editable={false}
                        name="CAARegistrationNumber"
                        component={Input}
                        placeholder=""
                        label="CAA Number"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    {/* <label>Address</label>
                    <Field
                    disabled
                      name="address"
                      as="textarea"
                      className="form-control"
                    /> */}
                  </div>
                  <div className="form-group">
                    {/* <label>address 2</label>
                    <Field
                    disabled
                      name="address2"
                      as="textarea"
                      className="form-control"
                    /> */}
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        disabled
                        name="issuingDate"
                        component={Input}
                        placeholder=""
                        label="CAA Issuing Date"
                      // customFeedbackLabel="Please enter number"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        disabled
                        name="validTo"
                        component={Input}
                        placeholder=""
                        label="Expiry Date"
                      // customFeedbackLabel="Please enter number"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        disabled
                        name="permittedSpeciesId.name"
                        component={Input}
                        placeholder=""
                        label="Permitted Species"
                      // customFeedbackLabel="Please enter number"
                      />
                    </div>
                  </div>


                  <div className="form-group row">
                    <div className="col-lg-12">
                      <button
                        type="submit"
                        style={{ width: "80%" }}
                        className="btn btn-primary ml-6"
                        onClick={userOnBoardingProcess}
                      >
                        Claim Ownership
                  </button>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </>
      </CardBody>
    </Card>

  );
}

export default injectIntl(connect(null, auth.actions)(UserOnBoardingProcessStepOne));
