/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";


export function TilesWidget21({
  className,
  baseColor = 'white',
  widgetHeight = "150px",
  props,
}) {
  const { dashboardData } = useSelector(
    (state) => ({ dashboardData: state.dashboardData }),
    shallowEqual
  );
    console.log("dashboardData",dashboardData)
  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight,backgroundColor:'#bad005' }}
      >
        <div className="card-body">
          <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} />
          </span>
          <div
            className={`text-${baseColor} font-weight-bolder font-size-h2 mt-3`}
          >
            {dashboardData.bookingsCount}
          </div>

          <a
            href="#"
            className={`text-${baseColor} font-weight-bold font-size-lg mt-1`}
          >
           Total Bookings
          </a>
        </div>
      </div>
    </>
  );
}
