import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { bookingsSlice } from "../app/modules/Bookings/_redux/bookings/bookingsSlice";
import { dashboardSlice } from "../app/modules/DashboardAPI/dashboardSlice";
import { reportsSlice } from "../app/modules/Reports/_redux/Reports/reportSlice"
import { paymentReceiptsSlice } from "../app/modules/PaymentReceipts/_redux/paymentReceipts/paymentReceiptsSlice"
import { transactionsSlice } from "../app/modules/Transactions/_redux/Transactions/transactionsSlice"
import { additionalChargesSlice } from "../app/modules/AdditionalCharges/redux/additionalChargesSlice"

export const rootReducer = combineReducers({
  auth: auth.reducer,
  // customers: customersSlice.reducer,
  bookings: bookingsSlice.reducer,
  dashboardData: dashboardSlice.reducer,
  reportsData: reportsSlice.reducer,
  paymentReceiptsData: paymentReceiptsSlice.reducer,
  transactionsData: transactionsSlice.reducer,
  additionalChargesData: additionalChargesSlice.reducer
  // remarks: remarksSlice.reducer,
  // specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
