import {createSlice} from "@reduxjs/toolkit";

const initialPaymentReceiptsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  paymentReceiptsForEdit: undefined,
  lastError: null,
  bookings: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const paymentReceiptsSlice = createSlice({
  name: "paymentReceiptsData",
  initialState: initialPaymentReceiptsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    bookingsFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.bookings = entities;
    },
    // getPaymentReceiptsById
    paymentReceiptFetched: (state, action) => {
      state.actionsLoading = false;
      state.paymentReceiptsForEdit = action.payload.paymentReceiptsForEdit;
      state.error = null;
    },
    // findPaymentReceiptss
    paymentReceiptsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    pastPaymentReceiptsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createPaymentReceipts
    paymentReceiptsCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.paymentReceipts);
    },
    // updatePaymentReceipts
    paymentReceiptsUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.paymentReceipts.id) {
          return action.payload.paymentReceipts;
        }
        return entity;
      });
    },
    // deletePaymentReceipts
    paymentReceiptsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deletePaymentReceipts
	/*
    paymentReceiptsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
	*/
    // paymentReceiptssUpdateState
    paymentReceiptsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
