/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect, Route } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_websoc/_helpers";
import { ContentRoute } from "../../../../_websoc/layout"
import Login from "./Login";
import UserOnBoardingProcess from "./UserOnBoardingProcess";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_websoc/_assets/sass/pages/login/classic/login-1.scss";
import { ResetPassword } from "./ResetPassword";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export function AuthPage() {
  return (
    <div >
      <div
        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat "
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/bg/A.jpg")})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          height: '100vh'
        }}
      >
        <div className="d-flex flex-column flex-root" style={{ overflowX: "hidden" }}>
          {/* {/begin::Login/} */}
          <div
            className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid "
            id="kt_login"

          >
            {/* {/begin::Aside/} */}
            <div
              className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
              style={{
                //  backgroundImage: `url(${toAbsoluteUrl("/media/bg/A.jpg")})`
                // backgroundImage: `url(${toAbsoluteUrl("/media/bg/Vannamei.jpg")})`,
                // width:"50%"

              }}

            >
              {/* {/begin: Aside Container/} */}
              <div className="d-flex flex-row-fluid flex-column justify-content-between">
                {/* start:: Aside header */}
                <div className="row">
                  <div className="col-md-4">
                    <Link to="/" className="flex-column-auto mt-5">
                      <img
                        style={{ marginLeft: "150px", height: "100px", marginTop: "-25px", width: "100px", marginBottom: "5px" }}
                        alt="Logo"
                        // className="max-h-70px"
                        src={toAbsoluteUrl("/media/logos/rgca-logo.png")}
                      />
                      {/* <p style={{marginLeft:"107%",marginTop:"-30px",color:"#ffffff"}}><b>RGCA</b></p> */}
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/" className="flex-column-auto mt-5">
                      <img
                        style={{ marginLeft: "120px", height: "100px", marginTop: "-25px", width: "150px", marginBottom: "5px" }}
                        alt="Logo"
                        // className="max-h-70px"
                        src={toAbsoluteUrl("/media/logos/mpeda-logo.png")}
                      />
                      {/* <p style={{marginLeft:"107%",marginTop:"-30px",color:"#ffffff"}}><b>RGCA</b></p> */}
                    </Link>
                  </div>
                  {/* <div className="col-md-10" >
                      <h3 style={{color:"#3699FF",width:"120%",marginLeft:"3%"}}>RAJIV GANDHI CENTER FOR AQUACULTURE</h3>
                      <h4 style={{color:"#3699FF",width:"120%",marginLeft:"3%"}}>AQUATIC QUARANTINE FACILITY FOR <b style={{fontWeight:"normal",fontSize:"15px"}}>Litopenaeus vannamei</b></h4>
                      <p style={{color:"#3699FF",marginLeft:"3%"}}>(MPEDA,Ministry of commerse industry,Govt of India)</p>
                      <p style={{color:"#3699FF",marginTop:"-10px",width:"120%",marginLeft:"3%"}}>TNFDC Hatchery Complex,Beach Road,Kapaleeswarar Nagar,Neetankaral,Chennai-600 041</p>
                </div> */}
                </div>

                <div style={{ marginLeft: "20%" }}>
                  <Carousel
                    showIndicators={true}
                    showThumbs={false}
                    autoPlay={true}
                    showStatus={false}
                    infiniteLoop={true}
                    showArrows={false}
                    width="80%"
                    style={{ marginLeft: "20%" }}
                  >
                    <div>
                      <img alt="One" src={toAbsoluteUrl("/media/bg/rgca_slider_one.jpg")} width="100%" height="350px" style={{ marginLeft: "0%", borderRadius: "8px" }}></img>
                    </div>
                    <div>
                      {/* <img src={toAbsoluteUrl("/media/bg/Vannamei.jpg")} width="65%" height="350px" style={{marginLeft:"20%",borderRadius:"8px"}}></img> */}
                      <img alt="Two" src={toAbsoluteUrl("/media/bg/rgca_slider_three.jpeg")} width="100%" height="350px" style={{ marginLeft: "0%", borderRadius: "8px" }}></img>
                    </div>
                    <div>
                      <img alt="Three" src={toAbsoluteUrl("/media/bg/Vannamei.jpg")} width="100%" height="350px" style={{ marginLeft: "0%", borderRadius: "8px" }}></img>
                    </div>
                  </Carousel>
                </div>

                <div  >
                  <h3 style={{ color: "#ffffff", width: "120%", marginLeft: "22%", marginTop: "5px" }}>Aquatic Quarantine Facility, </h3>
                  <h4 style={{ color: "#ffffff", width: "120%", marginLeft: "22%" }}>Rajiv Gandhi Centre for Aquaculture</h4>
                  <p style={{ color: "#ffffff", marginLeft: "22%", fontSize: "10px" }}>MPEDA. Ministry of Commerce & Industry, Govt. of India</p>
                  <p style={{ color: "#ffffff", marginTop: "-10px", width: "120%", marginLeft: "22%", fontSize: "10px" }}>TNFDC Hatchery Complex, Kapaleeswarar Nagar, Beach Road</p>
                  <p style={{ color: "#ffffff", marginTop: "-10px", width: "120%", marginLeft: "22%", fontSize: "10px" }}>Neelankarai-600115, Chennai, Tamil Nadu</p>
                </div>
                {/* end:: Aside header */}

                {/* start:: Aside content */}
                {/* <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 " style={{color:"#3699FF",marginTop:"-20%",marginLeft:"5%"}}>
                  AQUATIC QUARANTINE MONITORING SYSTEM
                  </h3>
                  <h4 style={{color:"#3699FF",marginLeft:"5%"}}>Welcome!!!</h4>
                <p className="font-weight-lighter  opacity-80" style={{color:"#3699FF",marginLeft:"5%"}}>
                  Hatchery Owners App
                  </p>
              </div> */}
                {/* end:: Aside content */}

                {/* start:: Aside footer for desktop */}
                {/* <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white" style={{marginTop:"-30px",marginLeft:"10px"}}>
                  &copy; 2020  Rajiv Gandhi Centre For Aquaculture
                  </div>
                {/* <div className="d-flex">
                    <Link to="/terms" className="text-white">
                      Privacy
                    </Link>
                    <Link to="/terms" className="text-white ml-10">
                      Legal
                    </Link>
                    <Link to="/terms" className="text-white ml-10">
                      Contact
                    </Link>
                  </div> 
              </div> */}
                {/* end:: Aside footer for desktop */}
              </div>
              {/* {/end: Aside Container/} */}
            </div>
            {/* {/begin::Aside/} */}

            {/* {/begin::Content/} */}
            <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
              {/* {/begin::Content header/} */}
              {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
                <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
              </div> */}
              {/* {/end::Content header/} */}

              {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <Switch>
                  <ContentRoute path="/auth/login" component={Login} />
                  <ContentRoute path="/auth/registration" component={Registration} />

                  <ContentRoute
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                  />

                  <ContentRoute
                    path="/auth/reset-password"
                    component={ResetPassword}
                  />


                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect from="/" exact={true} to="/auth/login" />
                  {/* <Redirect to="/auth/login"/> */}
                </Switch>
              </div>
              {/* {/end::Content body/} */}

              {/* begin::Mobile footer */}
              <div
                className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                  &copy; 2020  Rajiv Gandhi Centre For Aquaculture
                </div>
                <div className="d-flex order-1 order-sm-2 my-2">
                  <Link to="/terms" className="text-dark-75 text-hover-primary">
                    Privacy
                  </Link>
                  <Link
                    to="/terms"
                    className="text-dark-75 text-hover-primary ml-4"
                  >
                    Legal
                  </Link>
                  <Link
                    to="/terms"
                    className="text-dark-75 text-hover-primary ml-4"
                  >
                    Contact
                  </Link>
                </div>
              </div>
              {/* end::Mobile footer */}
            </div>
            {/* {/end::Content/} */}
          </div>
          {/* {/end::Login/} */}
        </div>
      </div>
    </div>
  );
}