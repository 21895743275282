import React, { useEffect } from "react";
import {
  TilesWidget21,
  TilesWidget22,
  TilesWidget24,
} from "../widgets";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../app/modules/DashboardAPI/dashboardActions";
import { Link } from "react-router-dom";


export function DashboardWebsoc() {

  const { totalBookings } = useSelector(
    (state) => ({ totalBookings: state.dashboardData }),
    shallowEqual
  );
  const { user } = useSelector(
    (state) => ({ user: state.auth.user }),
    shallowEqual
  );
  console.log("user", user)
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    // server call by queryParams

    dispatch(actions.fetchUserRoleAccess(user));
    dispatch(actions.fetchBooking());
    dispatch(actions.fetchReports());
    dispatch(actions.fetchTotalVsOpenBookings());
    dispatch(actions.fetchTotalVsOpenLAR());
    dispatch(actions.fetchHatcheryDetails(user.userProfile.hatcheryId));
    dispatch(actions.fetchNotifications());
    dispatch(actions.fetchTermsAndCondition());
    dispatch(actions.fetchBookingsForTransactions());



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (<>
    <div className="row">
      <div className="col-lg-6 col-xxl-4">
        <Link to="/Bookings/list"> <TilesWidget21 className="card-stretch gutter-b" /></Link>
      </div>
      {/* <div className="col-lg-6 col-xxl-4">
                    <TilesWidget11 count={totalBookings.totalCount} className="card-stretch gutter-b"/>
                </div> */}

      <div className="col-lg-6 col-xxl-4">
        <Link to="/transactions/list"><TilesWidget22 className="card-stretch gutter-b" /></Link>
      </div>
      <div className="col-lg-6 col-xxl-4">

        <Link to="/Reports/list"> <TilesWidget24 count={totalBookings.totalCount} className="card-stretch gutter-b" /></Link>
      </div>


    </div>
    <div className="row">

      {/* <div className="col-lg-6 col-xxl-4">
                 <TilesWidget23 className="card-stretch gutter-b"/>
                </div> */}


    </div>
  </>);
}
