import { Avatar, colors } from '@material-ui/core';
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/styles'
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import axios from 'axios';
import React, { useState } from "react";
import { Button, Card, Form, FormControl, InputGroup } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";
import Webcam from "react-webcam";
import swal from "sweetalert";
import * as _ from 'lodash';
import moment from 'moment'
import { sendOTPRequest } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
import { bankNames } from './BankNames';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  avatar: {
    margin: 0,
  },
  pinkAvatar: {
    margin: 0,
    color: '#fff',
    backgroundColor: colors.pink[500],
  },
  greenAvatar: {
    margin: 0,
    color: '#fff',
    size: 100,
    backgroundColor: colors.blue[500],
  },
}));

function UserOnBoardingProcessStepFinal(props) {

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  const classes = useStyles();
  const { intl } = props;

  const { HatcheryDetails } = useSelector(
    (state) => ({
      HatcheryDetails: state.auth.HatcheryDetails,
    }),
    shallowEqual
  );


  const [showBankForm, setShowBankForm] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [mobileNumberForOTP, setMobileNumberForOTP] = useState('');
  const [emailForOTP, setEmailForOTP] = useState('');
  const [validated, setValidated] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  
  // profile pictures related state
  const [profileFacePayload, setProfileFacePayload] = useState(null); 
  const [webCamForProfilePic, setWebCamForProfilePic] = useState(false);
  const [showAuth1Form, setShowAuth1Form] = useState(false);
  const [showAuth2Form, setShowAuth2Form] = useState(false);
  const [auth1FacePayload, setAuth1FacePayload] = useState(null);
  const [auth2FacePayload, setAuth2FacePayload] = useState(null);
  const [webCamForAuth1, setWebCamForAuth1] = useState(false);
  const [webCamForAuth2, setWebCamForAuth2] = useState(false);
  
  const bankDetailsForRef = {
    accountName: "",
    accountNumber: "",
    accountType: "",
    bankName: "",
    branchName: "",
    IFSC: "",
    bankAccountDetailProof: "",
  }
  const [hatcheryRegistration, setHatcheryRegistration] = useState(
    {
      name: "",
      address: "",
      phoneNumber: "",
      noOfBookingPerMonth: 0,
      email: "",
      password: "",
      picture: "",
      accountName: "",
      accountNumber: "",
      accountType: "",
      bankName: "",
      branchName: "",
      IFSC: "",
      bankAccountDetailProof: "",
      licence: " ",
      OTP: "",

      //Optional Bank 2
      bankDetails: {
        accountName: "",
        accountNumber: "",
        accountType: "",
        bankName: "",
        branchName: "",
        IFSC: "",
        bankAccountDetailProof: "",
      },
      authPerson1: {
        name: "",
        email: "",
        phoneNumber: "",
        picture: "",
        aadhaarNumber: ""
      },

      //Optional Auth 2F
      authPerson2: {
        name: "",
        email: "",
        phoneNumber: "",
        picture: "",
        aadhaarNumber: ""
      }

    })

  const [hatcheryRegistrationDocuments, setHatcheryRegistrationDocuments] = useState(
    {
      picture: "",
      licence: " ",
      bankAccountDetailProof: "",
      bankAccount2DetailProof: "",
       authPerson1: {
        picture: ""
      },
      authPerson2: {
        picture: ""
      }
    })

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };

  const webcamProfileRef = React.useRef(null);
  const webcamAuth1Ref = React.useRef(null);
  const webcamAuth2Ref = React.useRef(null);
  
  const captureProfilePic = React.useCallback(
    async () => {
      setLoading(true);
      //return base64 string of captured image
      const imageSrc = webcamProfileRef.current.getScreenshot();
      let response = await doFaceEnrol(imageSrc, "owner");
      if (response.status == "success") {
        setProfileFacePayload(response.fileResponse.url);
        setHatcheryRegistrationDocuments((hatcheryRegistrationDocuments) => {
        hatcheryRegistrationDocuments.picture = response.fileResponse.url;
          return ({
            ...hatcheryRegistrationDocuments
          })
        });
      } else {
         swal('Error', response.message, 'error');
      }          
      setWebCamForProfilePic(false);
      setLoading(false);
    },
    [webcamProfileRef]
  );

  const captureAuth1Pic = React.useCallback(
    async () => {
      setLoading(true);
      //return base64 string of captured image
      const imageSrc = webcamAuth1Ref.current.getScreenshot();
      let response = await doFaceEnrol(imageSrc, "auth1");
      if (response.status == "success") {
        setAuth1FacePayload(response.fileResponse.url);
        setHatcheryRegistrationDocuments((hatcheryRegistrationDocuments) => {
        hatcheryRegistrationDocuments.auth1Picture = response.fileResponse.url;
          return ({
            ...hatcheryRegistrationDocuments
          })
        });
      } else {
         swal('Error', response.message, 'error');
      }      
      setWebCamForAuth1(false);
      setLoading(false);
    },
    [webcamAuth1Ref]
  );

  const captureAuth2Pic = React.useCallback(
    async () => {
      setLoading(true);
      //return base64 string of captured image
      const imageSrc = webcamAuth2Ref.current.getScreenshot();
      let response = await doFaceEnrol(imageSrc, "auth2");
      if (response.status == "success") {
        setAuth2FacePayload(response.fileResponse.url)
        setHatcheryRegistrationDocuments((hatcheryRegistrationDocuments) => {
        hatcheryRegistrationDocuments.auth2Picture = response.fileResponse.url;
          return ({
            ...hatcheryRegistrationDocuments
          })
        });
      } else {
         swal('Error', response.message, 'error');
      }      
      setWebCamForAuth2(false);
      setLoading(false);
    },
    [webcamAuth2Ref]
  );

  const doFaceEnrol = async (imageSrc, profileType) => {
    const time = new Date().getTime();
    const dataImage = _.cloneDeep(imageSrc).toString()
      .replace("\n", "")
      .replace('data:image/png;base64,', '')
      .replace('data:image/jpeg;base64,', '')
    
    let fileUploadResponse = {};
    try {
      let payload = {
          "userid": "37",
          "livefaceimage": dataImage,
          "rrn": "45897855885588",
          "reftimestamp": moment.utc().toISOString(),
          "slk": "IVPAF-LSVMG-JMZCE-TXVEM",
          "servicecode": "23",
          "enrollmentid": `${HatcheryDetails.id}-${profileType}-${time}`
      };
      let enrollResponse = await axios.post(process.env.REACT_APP_FACE_ENROLL_URL, payload);
      if (enrollResponse.data.status == 1) {
        // enrollment successful, upload image to backend with filename
        fileUploadResponse = await uploadImage(imageSrc, `${HatcheryDetails.id}-${profileType}-${time}`);
        return {status : "success", message : 'Face registered successfully', fileResponse : fileUploadResponse};
        
      } else {
        // check the status and returned enrollment id
        if (enrollResponse.data.errdesc === 'Query face found') {
          
          let returnEnrolId = enrollResponse.data.enrolledid.split('-');
          
          // check parts of the enrollment id
          if (returnEnrolId[0] == HatcheryDetails.id) {
            let profile = returnEnrolId[1] === 'owner' ? 'owner' : 
              returnEnrolId[1] === 'auth1' ? 'authorized person 1' : 'authorized person 2';
              
            if (returnEnrolId[1] == profileType) {
              // this is not a error scenario, same face was found for same profile type
              // continue with file upload
              //
              fileUploadResponse = await uploadImage(imageSrc, enrollResponse.data.enrolledid);
              return {status : "success", message : 'Face registered successfully', fileResponse : fileUploadResponse};
             } else {
              return {
                status : "error", 
                message : 'Face is already registered with the current hatchery ' + profile + '.Please scan a different face. ' + 
                  'Previous face enrollment ID : ' + enrollResponse.data.enrolledid,
                fileResponse : null 
              };
            }
          } else {
            return {
              status : "error", 
              message : 'Face is already registered with a different hatchery. Please scan a different face. ' + 
                'Previous face enrollment ID : ' + enrollResponse.data.enrolledid,
              fileResponse : null 
            };
          }
          
        } else {
          return {
            status : "error", 
            message : enrollResponse.data.errdesc,
            fileResponse : null 
          };
        }
      }
    } catch (error) {
      console.log(error);
      return {
        status : "error", 
        message : error.message,
        fileResponse : null 
      };
    }
  }
  
  const uploadImage = async (base64, name) => {
      const blob = await fetch(_.cloneDeep(base64)).then(res => res.blob());
      const file = new File([blob], `${name}.png`, { type: "image/png" })
      const formData = new FormData();
      formData.append('files', file);
      try {
          const res = await axios.post(process.env.REACT_APP_UPLOAD_PATH, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });
          let data = res.data[0];
          data.base64 = base64;
          data.enrollmentId = name;
          return data;
      } catch (error) {
          swal("Error", error.response.data.message, "error");
      }
  }
  
  const handleChequeDocumentUpload = (document) => {
    const selectedFile = document;

    var formdata = new FormData();
    let fileNameSplit = selectedFile.name.split('.');
    let fileName = `bank_proof_1.${_.last(fileNameSplit)}`
    formdata.append("files", selectedFile, fileName);
    axios.post(process.env.REACT_APP_UPLOAD_PATH, formdata)
      .then(({ data }) => {
        setHatcheryRegistrationDocuments((hatcheryRegistrationDocuments) => {
          hatcheryRegistrationDocuments.bankAccountDetailProof = data[0].url;
          return ({
            ...hatcheryRegistrationDocuments
          })
        })
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          swal("Error", error.response.data.message, "error");
        }
        setLoading(false);
      });
  }

  const handleCheque2DocumentUpload = (document) => {
    const selectedFile = document;
    var formdata = new FormData();
    let fileNameSplit = selectedFile.name.split('.');
    let fileName = `bank_proof_2.${_.last(fileNameSplit)}`
    formdata.append("files", selectedFile, fileName);
    axios.post(process.env.REACT_APP_UPLOAD_PATH, formdata)
      .then(({ data }) => {
        setHatcheryRegistrationDocuments((hatcheryRegistrationDocuments) => {
          hatcheryRegistrationDocuments.bankAccount2DetailProof = data[0].url;
          return ({
            ...hatcheryRegistrationDocuments
          })
        })
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          swal("Error", error.response.data.message, "error");
        }
        setLoading(false);
      });
  }

  const handleCAADocumentUpload = (document) => {
    const selectedFile = document;
    var formdata = new FormData();
    let fileNameSplit = selectedFile.name.split('.');
    let fileName = `caa_document.${_.last(fileNameSplit)}`
    formdata.append("files", selectedFile, fileName);
    axios.post(process.env.REACT_APP_UPLOAD_PATH, formdata)
      .then(({ data }) => {
        setHatcheryRegistrationDocuments((hatcheryRegistrationDocuments) => {
          hatcheryRegistrationDocuments.licence = data[0].url;
          return ({
            ...hatcheryRegistrationDocuments
          })
        })
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          swal("Error", error.response.data.message, "error");
        }
        setLoading(false);
      });
  }

  const userOnBoardingProcessOTP = async () => {
    try {
      if (mobileNumberForOTP.length > 0 && emailForOTP.length > 0) {
        setLoading(true);
        await sendOTPRequest(mobileNumberForOTP, emailForOTP)
        swal("Success", `OTP sent on ${mobileNumberForOTP} and ${emailForOTP}`, "success");
        setLoading(false);
        setResendOTP(true);
      } else {
        swal("Error", 'Phone number and email are mandatory', "error");
      }

    } catch (err) {
      setLoading(false);
      setResendOTP(true);
      if (err.response && err.response.data && err.response.data.message) {
        swal("Error", err.response.data.message, "error");
      } else {
        swal("Error", err.message, "error");
      }
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'phoneNumber') {
      if (value.length !== 10) {
        swal("Error", "Please Enter 10 Digit Valid Phone Number", 'error')
      } else {
        setMobileNumberForOTP(value)
      }
    }

    if (name === 'email') {
      if (!validateEmail(value)) {
        swal("Error", "Please enter a valid email address", 'error')
      } else {
        setEmailForOTP(value)
      }
    }

    setHatcheryRegistration((hatcheryRegistration) => {
      hatcheryRegistration[name] = value;
      return ({
        ...hatcheryRegistration
      })
    })
  }

  const handleInputChangeBankDetails = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setHatcheryRegistration((hatcheryRegistration) => {
      hatcheryRegistration.bankDetails[name] = value;
      return ({
        ...hatcheryRegistration
      })
    })
  }
  
  const handleInputChangeAuthPerson1 = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'phoneNumber') {
      if (value.length > '10') {
        swal("Error", "Please Enter 10 Digit Valid Phone Number", 'error')
      }
    }
    setHatcheryRegistration((hatcheryRegistration) => {
      hatcheryRegistration.authPerson1[name] = value;
      return ({
        ...hatcheryRegistration
      })
    })
  }

  const handleInputChangeAuthPerson2 = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'phoneNumber') {
      if (value.length > '10') {
        swal("Error", "Please Enter 10 Digit Valid Phone Number", 'error')
      }
    }
    setHatcheryRegistration((hatcheryRegistration) => {
      hatcheryRegistration.authPerson2[name] = value;
      return ({
        ...hatcheryRegistration
      })
    })
    console.log(hatcheryRegistration)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    let response = {};
    
    if (form.checkValidity() === false) {
      swal("Error", "Please fill all the required fields", 'error');
      event.stopPropagation();
      return false;
    }
    
    if (!hatcheryRegistrationDocuments.picture) {
      swal("Error", "Please capture hatchery owner picture", 'error');
      return false;
    }
  
    userOnBoardingProcess(event);
    setValidated(true);
  };

  const userOnBoardingProcess = async (e) => {
    
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    
    hatcheryRegistration.picture = hatcheryRegistrationDocuments.picture;
    hatcheryRegistration.licence = hatcheryRegistrationDocuments.licence;
    hatcheryRegistration.bankAccountDetailProof = hatcheryRegistrationDocuments.bankAccountDetailProof;

    if (hatcheryRegistration.bankDetails) {
       hatcheryRegistration.bankDetails.bankAccountDetailProof = hatcheryRegistrationDocuments.bankAccount2DetailProof;
    }
    
    if (hatcheryRegistration.authPerson1) {
      hatcheryRegistration.authPerson1.picture = hatcheryRegistrationDocuments.auth1Picture;
    }
    if (hatcheryRegistration.authPerson2) {
      hatcheryRegistration.authPerson2.picture = hatcheryRegistrationDocuments.auth2Picture;
    }

    let formData = { ...hatcheryRegistration };
    let auth1 = formData.authPerson1
    let auth2 = formData.authPerson2
    let bank2 = formData.bankDetails
 
    if (auth1 && (auth1.aadhaarNumber === '' || auth1.email === '' || auth1.phoneNumber === '' || auth1.name === '' || auth1.picture === '')) {
      //Delete Auth Person Object if any values are empty
      delete formData.authPerson1;
    }

    if (auth2 && (auth2.aadhaarNumber === '' || auth2.email === '' || auth2.phoneNumber === '' || auth2.name === '' || auth2.picture === '')) {
      //Delete Auth Person Object if any values are empty
      delete formData.authPerson2;
    }

    if (bank2 && (bank2.accountName === '' || bank2.accountNumber === '' || bank2.accountType === '' || bank2.bankName === '' || bank2.branchName === '' || bank2.IFSC === '')) {
      //Delete Bank 2 Object if any values are empty
      delete formData.bankDetails;
    }

    formData.CAARegistrationNumber = HatcheryDetails.CAARegistrationNumber;

    try {
      await axios.post(`/hatcheries/registartion`, formData);
      swal("Success", "Your registration request has been submitted successfully", "success");
      props.history.push(`/auth/login`);
      setLoading(false);
    } catch (error) {
      console.log(error)
      if (error.response) {
        swal("Error", error.response.data.message, "error")
      } else {
        swal("Error", error.message, "error");
      }
      setLoading(false);
    };
  };
  
  const cleanupEnrollment = async (path) => {
    let filename = null, enrollment = null;
    console.log(path);
    if (path) {
      // extract the filename from full path
      filename = path.split('/');
      filename = filename[filename.length - 1];
      
      // extract enrollment id from filename, the first 3 parts
      filename = filename.split('_');
      if(filename.length > 3) {
        enrollment = `${filename[0]}-${filename[1]}-${filename[2]}`;
      } else {
        // not a valid enrollment id based filename
        return;
      }
    } else {
      // no valid enrollment found
      return;
    }
    
    console.log(filename);
    console.log(enrollment);
    
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
      let payload = {
        "userid": "37",
        "livefaceimage": base64String,
        "rrn": "45897855885588",
        "reftimestamp": moment.utc().toISOString(),
        "enrollmentid": enrollment
      };
      
      let response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/settings/facedelete', payload);
      if (response.data.status != 1) {
        swal('Error', 'Failed to delete face profile', 'error');
        return;
      }
      
      // also delete image from backend here !!!
      // extract the filename from full path
      let filehash = path.split('/');
      filehash = filehash[filehash.length - 1].split('.')[0];
      
      try {
        let fileEntity = await axios.get(process.env.REACT_APP_UPLOAD_PATH + '/files?hash=' + filehash);
        if (fileEntity.data) {
          // delete api 
          await axios.delete(process.env.REACT_APP_UPLOAD_PATH + '/files/' + fileEntity.data[0].id);
        }
      } catch(error) {
        console.log(error);
        swal('Error', 'Failed to delete profile picture', 'error');
      }
    }
    
    reader.onerror = error => console.log(error);
    
    let response = axios({ 
        url: process.env.REACT_APP_API_BASE_URL + path,
        method: 'GET',
        responseType: 'blob'
    }).then((response) => {
      reader.readAsDataURL(new Blob([response.data]));
    });
  }
    
   
  return (
    //  CAA INFO Block
    <>
      <Form noValidate validated={validated} style={{ width: '60%' }} onSubmit={e => { handleSubmit(e) }}>

        <Card style={{ width: '100%' }}>
          <Card.Header><h3>Hatchery License Details</h3></Card.Header>
          <Card.Body>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Upload CAA</Form.Label>
              <Form.Control id="file" accept="application/pdf" name="attachment0" type="file" onChange={(event) => {
                handleCAADocumentUpload(event.currentTarget.files[0]);
              }} className="form-control" required />
            </Form.Group>
          </Card.Body>
        </Card>

        {/* Basic Details Block       */}
        <Card style={{ width: '100%', marginTop: 20 }}>
          <Card.Header><h3>Enter Your Details</h3></Card.Header>
          <Card.Body>
            <Form.Group controlId="formContactDetails">
              <Form.Label>Your Name</Form.Label>
              <Form.Control autoComplete="off" type="text" name="name" onChange={(event) => {
                handleInputChange(event);
              }} placeholder="name" required />
              <Form.Label>Your Phone Number</Form.Label>
              <Form.Control
                autoComplete='off'
                type="number" name="phoneNumber" onBlur={(event) => {
                  handleInputChange(event);
                }} placeholder="Phone Number" required />
              <Form.Label>Your Email</Form.Label>
              <Form.Control
                autoComplete='off'
                type="text" name="email" onBlur={(event) => {
                  handleInputChange(event);
                }} placeholder="Email" required />
              <Form.Label>Address</Form.Label>
              <Form.Control
                autoComplete='off'
                type="text" name="address" onChange={(event) => {
                  handleInputChange(event);
                }} placeholder="Address" required />
              <Form.Label>Upload Your Picture</Form.Label>
              <Avatar className={classes.greenAvatar}>
                <AddPhotoIcon onClick={() => setWebCamForProfilePic(true)} />
              </Avatar>
              {
                profileFacePayload &&
                <div style={{ marginTop: '2%' }}> 
                  <img src={process.env.REACT_APP_API_BASE_URL + profileFacePayload} height="100px" /> 
                </div>
              }
              {webCamForProfilePic &&

                <div>
                  <div className="d-flex justify-content-start">
                    <Webcam
                      audio={false}
                      height={350}
                      ref={webcamProfileRef}
                      screenshotFormat="image/jpeg"
                      width={400}
                      videoConstraints={videoConstraints}
                    />
                  </div>
                  <Button
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    disabled={isLoading}
                    onClick={!isLoading ? captureProfilePic : null}>
                    Scan Face
                    {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                  </Button>
                </div>
              }
            </Form.Group>
            <Form.Group className="mb-3">
               <Form.Check type="checkbox" name="Auth1" label="Authorize others to use your account" onChange={async (event) => {
                 if (event.target.checked) {
                   setShowAuth1Form(true)
                 } else {
                   setShowAuth1Form(false)
                   setShowAuth2Form(false)
                   setHatcheryRegistration((hatcheryRegistration) => {
                     delete hatcheryRegistration.authPerson1;
                     delete hatcheryRegistration.authPerson2;
                     return ({
                       ...hatcheryRegistration
                     })
                   });
                   
                   await cleanupEnrollment(auth1FacePayload);
                   await cleanupEnrollment(auth2FacePayload);
                   
                   setAuth1FacePayload(null);
                   setAuth2FacePayload(null);
                   
                   delete hatcheryRegistrationDocuments.auth1Picture;
                   delete hatcheryRegistrationDocuments.auth2Picture;
                 }
               }} />
             </Form.Group>
          </Card.Body>
        </Card>
    
        {/* Auth One Block     */}
        {
          showAuth1Form &&
          <Card style={{ width: '100%', marginTop: 20 }}>
            <Card.Header><h3>Authtorized Person One Details</h3></Card.Header>
            <Card.Body>
              <Form.Group controlId="formAuth1">
                <Form.Label>Upload Picture</Form.Label>
                <Avatar className={classes.greenAvatar}>
                  <AddPhotoIcon onClick={() => setWebCamForAuth1(true)} />
                </Avatar>
                {
                  auth1FacePayload &&
                  <div style={{ marginTop: '2%' }}> 
                    <img src={process.env.REACT_APP_API_BASE_URL + auth1FacePayload} height="100px" /> 
                  </div>
                }
                {webCamForAuth1 &&
                  <div>
                    <div className="dflex justify-content-start">
                      <Webcam
                        audio={false}
                        height={350}
                        ref={webcamAuth1Ref}
                        screenshotFormat="image/jpeg"
                        width={400}
                        videoConstraints={videoConstraints}
                      />
                    </div>
                    <Button
                      className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      disabled={isLoading}
                      onClick={!isLoading ? captureAuth1Pic : null}
                    >
                      <span>Scan Face</span>
                      {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                    </Button>
                  </div>
                }
                <Form.Label>Name</Form.Label>
                <Form.Control autoComplete="off" type="text" name="name" value={hatcheryRegistration.value} onChange={(event) => {
                  handleInputChangeAuthPerson1(event);
                }} placeholder="Name" />
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  autoComplete='off'
                  type="number" name="phoneNumber" value={hatcheryRegistration.value} onChange={(event) => {
                    handleInputChangeAuthPerson1(event);
                  }} placeholder="Phone Number" />
                <Form.Label>Aadhar Number</Form.Label>
                <Form.Control
                  autoComplete='off'
                  type="number" name="aadhaarNumber" value={hatcheryRegistration.value} onChange={(event) => {
                    handleInputChangeAuthPerson1(event);
                  }} placeholder="Aadhar Number" />
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoComplete='off'
                  type="text" name="email" value={hatcheryRegistration.value} onChange={(event) => {
                    handleInputChangeAuthPerson1(event);
                  }} placeholder="Email" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check type="checkbox" name="Auth2" label="Add another authorized person details" onChange={async (event) => {
                  if (event.target.checked) {
                    setShowAuth2Form(true)
                  } else {
                    setShowAuth2Form(false);
                    await cleanupEnrollment(auth2FacePayload);
                    setAuth2FacePayload(null);
                    delete hatcheryRegistrationDocuments.auth2Picture;
                    setHatcheryRegistration((hatcheryRegistration) => {
                      delete hatcheryRegistration.authPerson2;
                      return ({
                        ...hatcheryRegistration
                      })
                    })
                  }
                }} />
              </Form.Group>
            </Card.Body>
          </Card>
        }

        {/* Auth Two Block */}
        {
          showAuth2Form &&
          <Card style={{ width: '100%', marginTop: 20 }}>
            <Card.Header><h3>Authtorized Person Two Details</h3></Card.Header>
            <Card.Body>
              <Form.Group controlId="formAuth2">
                <Form.Label>upload Profile Pictures</Form.Label>
                <Avatar className={classes.greenAvatar}>
                  <AddPhotoIcon onClick={() => setWebCamForAuth2(true)} />
                </Avatar>
                {
                  auth2FacePayload &&
                  <div style={{ marginTop: '2%' }}> 
                    <img src={process.env.REACT_APP_API_BASE_URL + auth2FacePayload} height="100px" /> 
                  </div>
                }
                {webCamForAuth2 &&

                  <div>
                    <div className="d-flex justify-content-start">
                      <Webcam
                        audio={false}
                        height={350}
                        ref={webcamAuth2Ref}
                        screenshotFormat="image/jpeg"
                        width={400}
                        videoConstraints={videoConstraints}
                      />
                    </div>
                    <Button
                      className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      disabled={isLoading}
                      onClick={!isLoading ? captureAuth2Pic : null}
                    >
                      <span>Scan Face</span>
                      {isLoading && <span className="ml-3 spinner spinner-white"></span>}

                    </Button>

                  </div>

                }
                <Form.Label>Name</Form.Label>
                <Form.Control autoComplete="off" type="text" name="name" onChange={(event) => {
                  handleInputChangeAuthPerson2(event);
                }} placeholder="name" />
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="number" name="phoneNumber" onChange={(event) => {
                  handleInputChangeAuthPerson2(event);
                }} placeholder="phoneNumber" />
                <Form.Label>Aadhar Number</Form.Label>
                <Form.Control type="number" name="aadhaarNumber" onChange={(event) => {
                  handleInputChangeAuthPerson2(event);
                }} placeholder="Aadhar Number" />
                <Form.Label>Email</Form.Label>
                <Form.Control autoComplete="off" type="text" name="email" onChange={(event) => {
                  handleInputChangeAuthPerson2(event);
                }} placeholder="email" />
              </Form.Group>
            </Card.Body>
          </Card>
        }
        {/* Primary Bank Block */}
          <Card style={{ width: '100%', marginTop: 20 }}>
            <Card.Header><h3>Bank Details</h3></Card.Header>
            <Card.Body>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Account Name</Form.Label>
                <Form.Control type="text" name="accountName" onChange={(event) => {
                  handleInputChange(event);
                }} placeholder="Account Name" required />
                <Form.Label>Account Number</Form.Label>
                <Form.Control autoComplete='off'
                  type="number" name="accountNumber" onChange={(event) => {
                    handleInputChange(event);
                  }} placeholder="Account Number" required />

                <Form.Label>Account Type</Form.Label>
                <Form.Control as="select" name="accountType" custom onChange={(event) => { handleInputChange(event) }} required>
                  <option value={''} >Select Account Type</option>
                  <option value="CORPORATE">Corporate</option>
                  <option value="PERSONAL">Personal</option>
                </Form.Control>

                <Form.Label>Bank Name</Form.Label>
                <Form.Control autoComplete='off' as="select" name="bankName" custom onChange={(event) => { handleInputChange(event) }} required>
                  <option value={''}>Select Bank Name</option>

                  {
                    bankNames.map((banks) => (
                      <option key={banks.bankName} value={banks.bankName}>
                        {banks.bankName}
                      </option>
                    ))}
                </Form.Control>

                <Form.Label>Branch Name</Form.Label>
                <Form.Control autoComplete='off' type="text" name="branchName" onChange={(event) => {
                  handleInputChange(event);
                }} placeholder="Branch Name" required />
                <Form.Label>IFSC</Form.Label>
                <Form.Control type="text" name="IFSC" onChange={(event) => {
                  handleInputChange(event);
                }} placeholder="IFSC" required />
                <Form.Label>Upload Cancelled Cheque / Passbook First Page</Form.Label>
                <Form.Control id="file" name="attachment1" accept="image/*, application/pdf" type="file" onChange={(event) => {
                  handleChequeDocumentUpload(event.currentTarget.files[0]);
                }} className="form-control" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="Add another bank account" onChange={(event) => {
                  if (event.target.checked) { setShowBankForm(true) } else { setShowBankForm(false) };
                }} />
              </Form.Group>
            </Card.Body>
          </Card>

        {/* Sec Bank Block */}
        {
          showBankForm &&
          <Card style={{ width: '100%', marginTop: 20 }}>
            <Card.Header><h3>Bank Details 2 (optional)</h3></Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Label>Account Name</Form.Label>
                <Form.Control
                  autoComplete='off'
                  type="text" name="accountName"
                  onChange={(event) => {
                    handleInputChangeBankDetails(event);
                  }}
                  placeholder="accountName" />
                <Form.Label>Account Number</Form.Label>
                <Form.Control autoComplete='off'
                  type="number" name="accountNumber" onChange={(event) => {
                    handleInputChangeBankDetails(event);
                  }} placeholder="Account Number" />


                <Form.Label>Account Type</Form.Label>

                <Form.Control as="select" name="accountType" custom onChange={(event) => { handleInputChangeBankDetails(event) }}>
                  <option>Select Account Type</option>
                  <option value="CORPORATE">Corporate</option>
                  <option value="PERSONAL">Personal</option>
                </Form.Control>

                <Form.Label>Bank Name</Form.Label>
                <Form.Control autoComplete='off' as="select" name="bankName" custom onChange={(event) => { handleInputChangeBankDetails(event) }}>
                  <option>Select Bank Name</option>
                  {
                    bankNames.map((banks) => (
                      <option key={banks.bankName} value={banks.bankName}>
                        {banks.bankName}
                      </option>
                    ))
                  }
                </Form.Control>
                <Form.Label>Branch Name</Form.Label>
                <Form.Control autoComplete='off' type="text" name="branchName" onChange={(event) => {
                  handleInputChangeBankDetails(event);
                }} placeholder="branchName" />
                <Form.Label>IFSC</Form.Label>
                <Form.Control autoComplete='off' type="text" name="IFSC" onChange={(event) => {
                  handleInputChangeBankDetails(event);
                }} placeholder="IFSC" />
                <Form.Label>Upload Cancelled Cheque / Passbook First Page</Form.Label>
                <Form.Control autoComplete="off" id="file" accept="image/*, application/pdf" name="attachment1" type="file" onChange={(event) => {
                  handleCheque2DocumentUpload(event.currentTarget.files[0]);
                }} className="form-control" />
              </Form.Group>
            </Card.Body>
          </Card>
        }

        {/* set password block */}
        <Card style={{ width: '100%', marginTop: 20 }}>
          <Card.Header><h3>Password Details</h3></Card.Header>
          <Card.Body>
            <InputGroup className="mb-3">
              <FormControl
                disable
                value={mobileNumberForOTP}
                placeholder="Mobile Number"
                aria-label="Mobile Number"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Append>
                {
                  <button
                    type="button"
                    disabled={isLoading}
                    onClick={userOnBoardingProcessOTP}
                    className="btn btn-secondary" >
                    {
                      isLoading && <span class="spinner-border text-primary" role="status" style={{ width: '1em', height: '1em' }}></span>
                    }
                    {
                      !isLoading ? resendOTP ? 'Resend OTP' : 'Send OTP' : ''
                    }
                  </button>
                }
              </InputGroup.Append>
            </InputGroup>
            <Form.Label>OTP</Form.Label>
            <Form.Control
              autoComplete="off" type="text" name="OTP" onChange={(event) => {
                handleInputChange(event);
              }} placeholder="OTP" required />
            <Form.Label>Password</Form.Label>
            <Form.Control autoComplete="off" type="password" name="password" onChange={(event) => {
              handleInputChange(event);
            }} placeholder="Password" required />
            <Button variant="primary" type="submit" >
              Submit
            </Button>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(UserOnBoardingProcessStepFinal));
