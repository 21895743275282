import axios from "axios";
import CryptoJS from "crypto-js";


// export const LOGIN_URL = "api/auth/login";
export const LOGIN_URL = '/auth/local';
export const UPDATE = '/user-profiles';
export const REGISTER_URL = "api/auth/register";
export const FETCH_HATCHERY_URL = "/unassigned-hatcheries";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const REQUEST_FORGOT_PASSWORD_API = "/local/forgot-password";
export const RESET_PASSWORD_API = "/local/reset-password";
export const ME_URL = "api/me";
export const USER_ROLES_URL = "auth/permissions";
export const UPLOAD_FILE_URL = "/upload";
export const SEND_OTP_URL = "/registration-otps"
export const LOGOUT_URL = 'auth/logout';

export function login(email, password) {
  const identifier = email;
  // encrypt body params before calling API
  let formData = CryptoJS.AES.encrypt(JSON.stringify({ identifier, password }),
    process.env.REACT_APP_BODY_ENCRYPT_KEY).toString();

  return axios.post(LOGIN_URL, { formData });
}

export function setUserProfile(id, user) {
  console.log(id, user);
  return axios.put(`${UPDATE}/${id}`, { user });
}

export function fetchHatchery(caa) {
  console.log(caa)
  const a = caa.value;
  const b = Object.values(caa);
  console.log(a, b)
  return axios.get(`${FETCH_HATCHERY_URL}?CAARegistrationNumber=${caa}`);
}

export function register(C) {
  return axios.post(REGISTER_URL, {});
}
export function sendOTPRequest(number, email) {
  return axios.post(`${SEND_OTP_URL}/${number}`, { "email": email });
}

export function uploadImage(data) {
  console.log(data)

  return axios.post(UPLOAD_FILE_URL, { data });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function forgotPasswordss(identifier) {
  return axios.post(REQUEST_FORGOT_PASSWORD_API, { identifier });
}

export function resetPassword(password, passwordConfirmation, code) {
  return axios.post(RESET_PASSWORD_API, { password, passwordConfirmation, code });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getUserRolesByToken(user) {
  console.log("called", user.payload.user.payload.user.role.id)
  // Authorization head should be fulfilled in interceptor.
  return axios.get(`${USER_ROLES_URL}`);
}

export function logout(token) {
  return axios.post(LOGOUT_URL, { jwt: token });
}
