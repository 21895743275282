import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  bookingsCount: 0,
  hatcheriesCount: 0,
  larCount: 0,
  fetchedReportsCount: null,
  TotalBookingsVsOpenBookings: 0,
  totalLARVsVerified: 0,
  bookingsCC: null,
  bookingsCountCC: 0,
  lastError: null,
  userRoleAccess: null,
  bookingsTransactions: null,
  bookingsCountTransactions: 0,
  hatcheryDetails: null,
  balanceBS: null,
  notifications: null,
  notificationsCount: 0,
  termsAndCondition: null,
  termsAndConditionCount: 0,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const dashboardSlice = createSlice({
  name: "dashboardData",
  initialState: initialProductsState,

  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    fetchedUserRoleAccess: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userRoleAccess = totalCount;
    },

    fetchedAllBSPerYear: (state, action) => {
      console.log(action.payload, "action.payload");
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.balanceBS = totalCount;
    },

    fetchedBooking: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.bookingsCount = totalCount;
    },
    fetchedHatcheryDetails: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.hatcheryDetails = data;
    },
    fetchedNotifications: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.notifications = entities;
      state.notificationsCount = totalCount;
    },



    fetchedTermsAndCondition: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.termsAndCondition = entities;
      state.termsAndConditionCount = totalCount;
    },




    fetchedBookingsForCC: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.bookingsCC = entities;
      state.bookingsCountCC = totalCount;
    },
    fetchedBookingsForTransactions: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.bookingsTransactions = entities;
      state.bookingsCountTransactions = totalCount;
      console.log(state.bookingsTransactions, state.bookingsCountTransactions, "in slice")

    },
    fetchedLARCount: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.larCount = totalCount;
    },
    fetchedHatcheriesCount: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.hatcheriesCount = totalCount;
    },
    fetchedReports: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.fetchedReportsCount = totalCount;
    },
    fetchedTotalVsOpenBookings: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.TotalBookingsVsOpenBookings = totalCount.length;
    },
    fetchedTotalVsOpenLAR: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.totalLARVsVerified = totalCount;
    },



  }
});
