/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch, Redirect,Route} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_websoc/_helpers";
import {ContentRoute} from "../../../../_websoc/layout"
import { shallowEqual, useSelector } from "react-redux";

import UserOnBoardingProcessStepOne from "./UserOnBoardingProcessStepOne";
import UserOnBoardingProcessStepFinal from "./UserOnBoardingProcessStepFinal";

import "../../../../_websoc/_assets/sass/pages/login/classic/login-1.scss";


export function UserOnBoardingProcess() {

  const {  HatcheryDetails } = useSelector(
    (state) => ({
     // actionsLoading: state.auth.actionsLoading,
     HatcheryDetails: state.auth.hatcheryDetails,
    }),
    shallowEqual
  );
console.log(HatcheryDetails,"hatcheryDetailshatcheryDetails")
  return (
      <>
       <div style={{width:"100%"}}  className="d-flex flex-column flex-root">
    {/*begin::Login*/}
    <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
        id="kt_login"
    >
      {/*begin::Aside*/}
      <div
          className="d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
            width:"100%"
          }}
      >
         {/* begin::Content body */}
         <div className="d-flex flex-column-fluid flex-center mt-50 mt-lg-0">
                <Switch>
                <ContentRoute path="/user-onboarding-process/step-1" component={UserOnBoardingProcessStepOne}/>
                <ContentRoute path="/user-onboarding-process/step-Final" component={UserOnBoardingProcessStepFinal}/>
                <Redirect from="/user-onboarding-process/" exact={true} to="/user-onboarding-process/step-1"/> 
                {/* <Redirect from="/auth" exact={true} to="/auth/login"/>
                <Redirect from="/" exact={true} to="/auth/login"/> */}
              </Switch>
              </div>
              {/*end::Content body*/}
        </div></div></div>
        
      </>
  );
}
