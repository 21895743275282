import thunk from 'redux-thunk';
import {rootReducer,rootSaga} from './rootReducer';
import {persistStore, persistReducer} from 'redux-persist';
import {createStore, applyMiddleware} from 'redux';
//import axios from 'axios';
import createSagaMiddleware from "redux-saga";
import localForage from 'localforage';
//import { composeWithDevTools } from "redux-devtools-extension";
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: localForage,
  timeout: 0,
  blacklist: [
    'loginReducer',
  ], // values will not be persisted
  whitelist: [
      'dashboardData',
      
      
  ], // values should be persisted
  //stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

// Axios Middleware to send token
/*
function createAxiosAuthMiddleware() {
  console.log("createAxiosAuthMiddleware");
  return ({getState}) => next => action => {
    const {accessToken} = getState().accessTokenReducer;
    axios.defaults.headers.common.Authorization = accessToken
      ? `Bearer ${accessToken.access_token}`
      : null;

    return next(action);
  };
}
*/

const persistedReducer = persistReducer(persistConfig, rootReducer);

//const composeEnhancers = composeWithDevTools({});

function configureStore(initialState = {}) {
  return createStore(
    persistedReducer,
    initialState,
    //composeEnhancers(applyMiddleware(thunk, axiosAuth))
   applyMiddleware(thunk,sagaMiddleware),
  );
}

const store = configureStore();

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);


export default store;