import { createSlice } from "@reduxjs/toolkit";


const initialAdditionalChargesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    additionalchargeForView: undefined,
    lastError: null,
    additionalCharges: null
};


export const callTypes = {
    list: "list",
    action: "action"
};


export const additionalChargesSlice = createSlice({
    name: "additionalChargesData",
    initialState: initialAdditionalChargesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        chargesFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            console.log(action.payload, "MMM")
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
        },

        additionalChargeFetched: (state, action) => {
            state.actionsLoading = false;
            state.additionalchargeForView = action.payload.additionalchargeForView;
            state.error = null;
        },

    }
});