import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_websoc/layout";
import * as auth from "../_redux/authRedux";
import { logout } from "../_redux/authCrud";


class Logout extends Component {
  componentDidMount() {
    logout(this.props.authToken).then(response => {
      if (response.status !== 200) {
        throw Error(response.statusText);
      } else {
        this.props.logout();
      }
    }).catch(error => console.log(error));
  }

  render() {
    const { authToken } = this.props;
    return Boolean(authToken) ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(
  (state) => ({ authToken: state.auth.authToken }),
  auth.actions,
)(Logout);
