// /**
//  * High level router.
//  *
//  * Note: It's recommended to compose related routes in internal router
//  * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
//  */

// import React,{Suspense, lazy} from "react";
// import { Redirect, Switch, Route } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
// import {Layout} from "../_websoc/layout";
// import BasePage from "./BasePage";
// import { Logout, AuthPage, UserOnBoardingProcess } from "./modules/Auth";
// // import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";




// export function Routes() {
//     const {isAuthorized,user} = useSelector(
//         ({auth}) => ({
//             isAuthorized: auth.user != null,
//             user : auth.user
//         }),
//         shallowEqual
//     );
//         console.log(isAuthorized,user)
//     return (
//         <Switch>
//             {!isAuthorized ? (
//                 /*Render auth page when user at `/auth` and not authorized.*/
//                 <>
//                 <Redirect from="/" to="/auth/login"/>
//                 <Redirect from="/auth" to="/auth/login"/>
//                 <Route path="/auth/login" component={AuthPage}/>
//                 <Route path="/auth/registration" component={AuthPage}/>
//                 <Route path="/auth/forgot-password" component={AuthPage}/>
//                 <Route path="/user-onboarding-process/step-1" component={UserOnBoardingProcess}/>
//                 <Route path="/user-onboarding-process/step-Final" component={UserOnBoardingProcess}/>
//                 <Route path="/user-onboarding-process" exact component={UserOnBoardingProcess}/>
                
//                 </>
//             ) : (
//                 /*Otherwise redirect to root page (`/`)*/
//                 <Redirect from="/auth" to="/"/>
//             )}

//             {/* <Route path="/error" component={ErrorsPage}/> */}
//             <Route path="/logout" component={Logout}/>
//             {!isAuthorized ? (
//                 /*Redirect to `/auth` when user is not authorized*/
//                 <Redirect to="/auth/login"/>
//             ) : (
//                 <Layout>
//                     <BasePage/>
//                 </Layout>
//             )}
           
//         </Switch>
//     );
// }
/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

 import React, { Suspense, lazy } from "react";
 import { Redirect, Switch, Route } from "react-router-dom";
 import { shallowEqual, useSelector } from "react-redux";
 import { Layout } from "../_websoc/layout";
 import BasePage from "./BasePage";
 import { Logout, AuthPage, UserOnBoardingProcess } from "./modules/Auth";
 import { ResetPassword } from "./modules/Auth/pages/ResetPassword";
 import ForgotPassword from "./modules/Auth/pages/ForgotPassword";
 // import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
 
 
 
 
 export function Routes() {
     const { isAuthorized, user } = useSelector(
         ({ auth }) => ({
             isAuthorized: auth.user != null,
             user: auth.user
         }),
         shallowEqual
     );
     console.log(isAuthorized, user)
     return (
         <Switch>
             {!isAuthorized ? (
                 /*Render auth page when user at `/auth` and not authorized.*/
                 <>
                     <Redirect from="/" to="/auth/login" />
                     <Redirect from="/auth" to="/auth/login" />
                     <Route path="/auth/login" component={AuthPage} />
                     <Route path="/auth/registration" component={AuthPage} />
                     <Route path="/auth/forgot-password" component={AuthPage} />
                     <Route path="/auth/reset-password" exact component={AuthPage} />
                     <Route path="/user-onboarding-process/step-1" component={UserOnBoardingProcess} />
                     <Route path="/user-onboarding-process/step-Final" component={UserOnBoardingProcess} />
                     <Route path="/user-onboarding-process" exact component={UserOnBoardingProcess} />
 
                 </>
             ) : (
                
                 <Redirect from="/auth" to="/" />
             )}
 
             {/* <Route path="/error" component={ErrorsPage}/> */}
             <Route path="/logout" component={Logout} />
             {!isAuthorized ? (
               
                 <Redirect to="/auth/login" />
             ) : (
                 <Layout>
                     <BasePage />
                 </Layout>
             )}
 
         </Switch>
     );
 }