export default function setupAxios(axios, store) {

  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  // Add a response interceptor
  axios.interceptors.response.use(

    (response) => { return response; },
    (error) => {
      console.log(error);
      const { auth: { authToken } } = store.getState();

      // Do something with response error
      if (authToken && error.response.status === 401) {
        window.location.replace(process.env.PUBLIC_URL + "/logout");
      } else {
        return Promise.reject(error)
      }
    });
}
