import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_websoc/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { BeforeBooking } from "./modules/Bookings/pages/Bookings/booking-create/BeforeBooking";
import { BookingCreate } from "./modules/Bookings/pages/Bookings/booking-create/BookingCreate";
import PersonaInformation from "./modules/HatcheryDetails/PersonaInformation"

const BookingsPage = lazy(() =>
  import("./modules/Bookings/pages/BookingsPage")
);

const PastBookingsPage = lazy(() =>
  import("./modules/PastBooking/pages/PastBookingsPage")
);

const UserProfilePage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const ReportsPage = lazy(() =>
  import("./modules/Reports/pages/ReportsPage")
);

const PaymentsInvoicesPage = lazy(() =>
  import("./modules/Transactions/pages/TransactionsPage")
);

const PaymentsReceiptsPage = lazy(() =>
  import("./modules/PaymentReceipts/pages/PaymentsReceiptsPage")
);

const AdditionalChargesPage = lazy(() =>
  import("./modules/AdditionalCharges/pages/AdditionalChargPage")
);

const HowToUsePage = lazy(() =>
  import("./modules/How-to-use/pages/HowToUsePage")
);

export default function BasePage() {
  const dispatch = useDispatch();

  const { userRoleAccess } = useSelector(
    (state) => ({
      userRoleAccess: state.dashboardData.userRoleAccess,
    }),
    shallowEqual
  );
  // const ModuleLevelAccessRestriction = userRoleAccess.role.permissions.application.controllers;
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/bookings" component={BookingsPage} />
        <Route path="/past-bookings" component={PastBookingsPage} />
        <Route path="/user-profile" component={UserProfilePage} />
        <Route path="/hatchery-details" component={PersonaInformation} />
        <Route path="/Reports" component={ReportsPage} />
        <Route path="/transactions" component={PaymentsInvoicesPage} />
        <Route path="/additional-charges" component={AdditionalChargesPage} />
        <Route path="/payment-receipts" component={PaymentsReceiptsPage} />
        <Route path="/how-to-use" component={HowToUsePage} />
        <Route path="/bookings/new" component={BookingCreate} />
        <Route path="/face-match" component={BeforeBooking} />
        {/* <Redirect to="error/error-v1"/> */}
      </Switch>
    </Suspense>
  );
}
