import { Avatar, colors } from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { Field, Form, Formik } from "formik";
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { Button, Figure, Tab, Tabs } from 'react-bootstrap';
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import swal from 'sweetalert';
import * as Yup from 'yup';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar, Input, ModalProgressBar
} from "../../../_websoc/_partials/controls";
import * as auth from "../Auth";
import * as actions from "../DashboardAPI/dashboardActions";
import AuthorizedPersonDetails from './components/AuthorizedPersonDetails';
import BankDetails from './components/BankDetails';
import FaceRecognition from './components/FaceRecognition';
import Licenses from './components/Licenses';

const useStyles = makeStyles(() => ({
  greenAvatar: {
    margin: 0,
    color: '#fff',
    size: 100,
    backgroundColor: colors.blue[500],
  },
}));

function PersonaInformation(props) {
  const classes = useStyles();

  const hatchery = useSelector((state) => state.dashboardData.hatcheryDetails);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const [otpLabel, setOtpLabel] = useState('Send OTP');
  const [isLoading, setLoading] = useState(false);
  const [isOTPLoading, setOTPLoading] = useState(false);
  const [webCamForUserPic, setWebCamForUserPic] = useState(false);
  const [userPic, setUserPic] = useState('');
  const [profilePictureError, setProfilePictureError] = useState(false);
  const [mobileNumberForOTP, setMobileNumberForOTP] = useState(hatchery ? hatchery.userProfile.phoneNumber : '');
  const [phoneChange, setPhoneChange] = useState(false);
  const [otp, setOTP] = useState('');
  
  useEffect(() => {
    dispatch(actions.fetchHatcheryDetails(user.userProfile.hatcheryId));
    dispatch(actions.fetchNotifications());
  }, [true]);

  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.dashboardData.actionsLoading,
    }),
    shallowEqual
  );

  const savehatchery = async (values) => {

    var payload = {};
    
    setLoading(true);
    
    if (userPic) {
      let response = await doFaceEnrol(userPic, 'owner');
      if (response.status !== 'success') {
        swal('Error', response.message, 'error');
        setLoading(false);
        return;
      }
      payload['picture'] =  response.fileResponse.url;
      await cleanupEnrollment(hatchery.userProfile.picture);
      setUserPic(process.env.REACT_APP_ATTACHMENT_URL + response.fileResponse.url);
    }
    
    try {
      if (hatchery.userProfile.phoneNumber !== mobileNumberForOTP) {
        payload['phoneNumber'] = mobileNumberForOTP;
        if (otp.length <= 0)
          throw Error("OTP is required")
        else
          payload['OTP'] = otp;
      }

      if (_.keys(payload).length > 0) {
        await axios.put(`user-profiles/${hatchery.userProfile.id}`, payload);
        swal("Success", "Profile updated successfully", "success");
        dispatch(actions.fetchHatcheryDetails(user.userProfile.hatcheryId));
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      
      // clean up any face enrollment if it was done
      if (payload['picture']) {
        await cleanupEnrollment(payload['picture']);
      }
      
      if (err.response)
        swal("Failed to Save", err.response.data.message, "error");
      else
        swal("Error", err.message, "error");
    }
  }

  const updateHatcheryLicense = async (fileUploadResponse) => {
    try {
      await axios.put(process.env.REACT_APP_API_BASE_URL + '/hatcheries/' + user.userProfile.hatcheryId, { 'licence': fileUploadResponse.url });
      dispatch(actions.fetchHatcheryDetails(user.userProfile.hatcheryId));
      swal("License uploaded successfully", "", "success");

    } catch (error) {
      if (error.response) {
        swal(error.response.data.message, "", "error");
      } else {
        swal(error.message, "", "error");
      }
    }
  }

  const saveAuthDetails = async (values, imageSrc, id) => {

    let AuthPersons = {}
    let formData = _.cloneDeep(values);
    
    setLoading(true);
    
    if (imageSrc) {
      let response = await doFaceEnrol(imageSrc, id === 1 ? 'auth1' : 'auth2');
      if (response.status !== 'success') {
        swal('Error', response.message, 'error');
        setLoading(false);
        return;
      }
      
      if (id === 1 && hatchery.userProfile.authPerson1) {
        await cleanupEnrollment(hatchery.userProfile.authPerson1.picture);
      } else if (id === 2 && hatchery.userProfile.authPerson2) {
        await cleanupEnrollment(hatchery.userProfile.authPerson2.picture);
      }
   
      formData.picture = response.fileResponse.url;
    }
    
    if (id === 1) {
      AuthPersons = {
        "authPerson1": formData
      }
    } else if (id === 2) {
      AuthPersons = {
        "authPerson2": formData
      }
    }
  
    try {
      await axios.put(`user-profiles/${hatchery.userProfile.id}`, AuthPersons);
      dispatch(actions.fetchHatcheryDetails(user.userProfile.hatcheryId));
      swal("Success", "Data Saved successfully and submitted for approval", "success");
      setLoading(false);
    } catch (err) {
      
      // clean up any face enrollment if it was done
      if (formData.picture) {
        await cleanupEnrollment(formData.picture);
      }
      
      setLoading(false);
      if (err.response)
        swal(err.response.data.message, "Failed To Save", "error");
      else
        swal(err.message, "Failed To Save", "error");
    }
  }

  const saveBankAccounts = async (values) => {
    try {
      if (values.id) {
        await axios.put(`bank-account-details/${values.id}`, values)
      } else {
        await axios.post(`bank-account-details`, values)
      }
      swal("Data Saved successfully and submitted for Approval", "", "success");
      dispatch(actions.fetchHatcheryDetails(user.userProfile.hatcheryId));
    } catch (err) {
      if (err.response)
        swal(err.response.data.message, "", "error");
      else
        swal(err.message, "", "error");
    }
  }

  const handlePhoneChange = (event) => {
    setMobileNumberForOTP(event.currentTarget.value)
    setPhoneChange(true)
  }

  const handleOTPChange = (event) => {
    setOTP(event.currentTarget.value)
  }

  const userOnBoardingProcessOTP = () => {
    setOTPLoading(true)
    if (mobileNumberForOTP === hatchery.userProfile.phoneNumber) {
      swal('This Phone Number Already Linked To Your Account', '', 'error')
    } else {
      var instance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
      });
	  console.log("user email - " + user.email);
      instance.post(`registration-otps/${mobileNumberForOTP}`, { "email": user.email })
        .then(({ data }) => {
          setOTPLoading(false)
          swal("Success", `OTP sent on ${mobileNumberForOTP} and ${user.email}`, "success");
        })
        .catch((err) => {
          swal("Error", err.response.data.message, "error");
          setOtpLabel('Resend OTP');
        }).finally(() => {
          setOTPLoading(false)
        });
    }
  };
  
  const doFaceEnrol = async (imageSrc, profileType) => {
      const time = new Date().getTime();
      const dataImage = _.cloneDeep(imageSrc).toString()
        .replace("\n", "")
        .replace('data:image/png;base64,', '')
        .replace('data:image/jpeg;base64,', '')
      
      try {
        let payload = {
            "userid": "37",
            "livefaceimage": dataImage,
            "rrn": "45897855885588",
            "reftimestamp": moment.utc().toISOString(),
            "slk": "IVPAF-LSVMG-JMZCE-TXVEM",
            "servicecode": "23",
            "enrollmentid": `${hatchery.id}-${profileType}-${time}`
        };
        let enrollResponse = await axios.post(process.env.REACT_APP_FACE_ENROLL_URL, payload);
        if (enrollResponse.data.status == 1) {
          // enrollment successful, upload image to backend with filename
          let fileUploadResponse = await uploadImage(imageSrc, `${hatchery.id}-${profileType}-${time}`);
          
          return {status : "success", message : 'Face registered successfully', fileResponse : fileUploadResponse};
          
        } else {
          // check the status and returned enrollment id
          if (enrollResponse.data.errdesc === 'Query face found') {
            
            let returnEnrolId = enrollResponse.data.enrolledid.split('-');
            
            /*
              // test code to clean up stale enrollment - don't enable this code
              let payload = {
                "userid": "37",
                "livefaceimage": imageSrc.replace('data:', '').replace(/^.+,/, ''),
                "rrn": "45897855885588",
                "reftimestamp": moment.utc().toISOString(),
                "enrollmentid": enrollResponse.data.enrolledid
              };
            
              await axios.post(process.env.REACT_APP_API_BASE_URL + '/settings/facedelete', payload);
            */
            
            // check parts of the enrollment id
            if (returnEnrolId[0] == hatchery.id) {
              let profile = returnEnrolId[1] === 'owner' ? 'owner' : 
                returnEnrolId[1] === 'auth1' ? 'authorized person 1' : 'authorized person 2';
                return {
                  status : "error", 
                  message : 'Face is already registered with the current hatchery ' + profile + '.Please scan a different face. ' + 
                    'Previous face enrollment ID : ' + enrollResponse.data.enrolledid,
                  fileResponse : null 
                };
            } else {
              return {
                status : "error", 
                message : 'Face is already registered with a different hatchery. Please scan a different face. ' + 
                  'Previous face enrollment ID : ' + enrollResponse.data.enrolledid,
                fileResponse : null 
              };
            }
            
          } else {
            return {
              status : "error", 
              message : enrollResponse.data.errdesc,
              fileResponse : null 
            };
          }
        }
      } catch (error) {
        console.log(error);
        return {
          status : "error", 
          message : error.message,
          fileResponse : null 
        };
      }
    }
    
    const cleanupEnrollment = async (path) => {
      let filename = null, enrollment = null;
      console.log(path);
      if (path) {
        // extract the filename from full path
        filename = path.split('/');
        filename = filename[filename.length - 1];
        
        // extract enrollment id from filename, the first 3 parts
        filename = filename.split('_');
        if(filename.length > 3) {
          enrollment = `${filename[0]}-${filename[1]}-${filename[2]}`;
        } else {
          // not a valid enrollment id based filename
          return;
        }
      } else {
        // no valid enrollment found
        return;
      }
      
      console.log(filename);
      console.log(enrollment);
      
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
        let payload = {
          "userid": "37",
          "livefaceimage": base64String,
          "rrn": "45897855885588",
          "reftimestamp": moment.utc().toISOString(),
          "enrollmentid": enrollment
        };
            
        let response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/settings/facedelete', payload);
        if (response.data.status != 1) {
          swal('Error', 'Failed to delete face profile', 'error');
          return;
        }
        
        // also delete image from backend here !!!
        // extract the filename from full path
        let filehash = path.split('/');
        filehash = filehash[filehash.length - 1].split('.')[0];
        
        try {
          let fileEntity = await axios.get(process.env.REACT_APP_UPLOAD_PATH + '/files?hash=' + filehash);
          if (fileEntity.data) {
            // delete api 
            await axios.delete(process.env.REACT_APP_UPLOAD_PATH + '/files/' + fileEntity.data[0].id);
          }
        } catch(error) {
          console.log(error);
          swal('Error', 'Failed to delete profile picture', 'error');
        }
      }
      
      reader.onerror = error => console.log(error);
      
      let response = axios({ 
          url: process.env.REACT_APP_API_BASE_URL + path,
          method: 'GET',
          responseType: 'blob'
      }).then((response) => {
        reader.readAsDataURL(new Blob([response.data]));
      });
    }
    
    const uploadImage = async (base64, name) => {
        const blob = await fetch(_.cloneDeep(base64)).then(res => res.blob());
        const file = new File([blob], `${name}.png`, { type: "image/png" })
        const formData = new FormData();
        formData.append('files', file);
        try {
            const res = await axios.post(process.env.REACT_APP_UPLOAD_PATH, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            let data = res.data[0];
            data.base64 = base64;
            data.enrollmentId = name;
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
              swal("Error", error.response.data.message, "error");
            }
        }
    }
    
  return (
    <>
      <Card >
        {actionsLoading && <ModalProgressBar />}
        <CardHeader title="Hatchery Details">
          <CardHeaderToolbar>

          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="mt-5">
            <>
              <Tabs defaultActiveKey="BasicDetails" id="uncontrolled-tab-example">
                <Tab eventKey="BasicDetails" style={{ margin: 20 }} title="Basic Details">
                  <Formik
                    enableReinitialize={true}
                    initialValues={hatchery}
                    onSubmit={(values) => {
                      savehatchery(values);
                    }}
                  >
                    {() => (
                      <Form className="form form-label-right">
                        <div className="form-group row">
                          <div className="col-lg-4">
                            {
								userPic ?
								(<Figure>
                  <Figure.Image name="picture" src={userPic} width="171" height="180" style={{ border: 'solid 1px #E4E6EF', borderRadius: '0.42em' }}/>
								</Figure>)  :
							  (<>
							  { 
								hatchery && hatchery.userProfile && hatchery.userProfile.picture &&
							  (<Figure>
                                  <Figure.Image name="picture" src={process.env.REACT_APP_ATTACHMENT_URL + hatchery.userProfile.picture} width="171" height="180" style={{ border: 'solid 1px #E4E6EF', borderRadius: '0.42em' }}/>
                               </Figure>) 
							  } </>)
							}
							<Avatar className={classes.greenAvatar}>
                            <AddPhotoIcon onClick={() => setWebCamForUserPic(true)} />
                            </Avatar>
                            
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-4">
                            <Field
                              disabled
                              name="name"
                              component={Input}
                              placeholder=" Name"
                              label=" Name"
                            />
                          </div>
                          <div className="col-lg-4">
                            <Field
                              disabled
                              name="contactNumber"
                              component={Input}
                              placeholder="Contact Number"
                              label="Contact Number"
                            />
                          </div>
                          <div className="col-lg-4">
                            <Field
                              disabled
                              name="contactPerson"
                              component={Input}
                              placeholder="Contact Person Name"
                              label="Contact Person Name"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-4">
                            <Field
                              disabled
                              name="email"
                              component={Input}
                              placeholder="Email Id"
                              label="Email Id"
                            />
                          </div>
                          <div className="col-lg-4">
                            <label>Address</label>
                            <Field
                              disabled
                              rows={6}
                              name="address"
                              as="textarea"
                              className="form-control"
                            />
                          </div>
                          <div className="col-lg-4">
                            <label>Address2</label>
                            <Field
                              disabled
                              rows={6}
                              name="address2"
                              as="textarea"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-4">
                            <Field
                              disabled
                              name="userProfile.name"
                              component={Input}
                              placeholder="Name"
                              label="Name"
                            />
                          </div>
                          <div className="col-lg-4">
                            <Field
                              disabled={hatchery.updateRequestFlag === "ApprovalPending"}
                              name="userProfile.created_at"
                              component={Input}
                              placeholder="RegisteredDate"
                              label="Registered Date"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-4">
                            <Field
                              name="newContactNumber"
                              value={mobileNumberForOTP}
                              component={Input}
                              onChange={handlePhoneChange}
                              placeholder="New Contact Number"
                              label="New Contact Number"
                            />
                          </div>
                          <div className="col-lg-4" >
                            <button
                              type="button"
                              disabled={!phoneChange || isOTPLoading}
                              onClick={userOnBoardingProcessOTP}
                              style={{ marginTop: '7%', backgroundColor: '#3699ff', color: '#fff' }}
                              className="btn btn-primary ml-2">
                              {otpLabel}
                              {isOTPLoading && <span className="ml-3 mr-3 spinner spinner-white"></span>}
                            </button>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-4">
                            <Field
                              name="otp"
                              disabled={!phoneChange}
                              value={otp}
                              type="number"
                              component={Input}
                              onChange={handleOTPChange}
                              placeholder="OTP"
                              label="OTP"
                            />
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary ml-2"
                          disabled={isLoading}
                        >
                          Save
                          {isLoading && <span className="ml-3 mr-3 spinner spinner-white"></span>}
                        </button>

                      </Form>
                    )}
                  </Formik>
                </Tab>
                <Tab eventKey="AuthPersons" style={{ margin: 20 }} title="Authorized Persons">
                  {/* Auth Person 1 block      */}
                  <AuthorizedPersonDetails
                    title="Authenticated Person 1"
                    hatchery={hatchery}
                    details={hatchery.userProfile.updateRequestDetails && hatchery.userProfile.updateRequestDetails.authPerson1 ? 
                             hatchery.userProfile.updateRequestDetails.authPerson1 : 
                             hatchery.userProfile.authPerson1}
                    isLoading={isLoading}
                    isPending={hatchery.userProfile.updateRequestDetails && hatchery.userProfile.updateRequestDetails.authPerson1}
                    onSave={(values, imageSrc) => saveAuthDetails(values, imageSrc, 1)} />
                  {/* Auth Person 2 block      */}
                  <AuthorizedPersonDetails
                    title="Authenticated Person 2"
                    details={hatchery.userProfile.updateRequestDetails && hatchery.userProfile.updateRequestDetails.authPerson2 ? 
                             hatchery.userProfile.updateRequestDetails.authPerson2 : 
                             hatchery.userProfile.authPerson2}
                    hatchery={hatchery}
                    isLoading={isLoading}
                    isPending={hatchery.userProfile.updateRequestDetails && hatchery.userProfile.updateRequestDetails.authPerson2}
                    onSave={(values, imageSrc) => saveAuthDetails(values, imageSrc, 2)} />
                </Tab>
                <Tab eventKey="bankAccounts" style={{ margin: 20 }} title="Bank Accounts">
                  {/* bank 1 */}
                  <BankDetails title="Bank Details 1"
                    id={1}
                    onSave={saveBankAccounts}
                    isLoading={isLoading}
                    details={0 in hatchery.bankDetails ?
                      (hatchery.bankDetails[0].updateRequestDetails ? hatchery.bankDetails[0].updateRequestDetails : hatchery.bankDetails[0]) : 
                      {}
                    }
                    isPending={0 in hatchery.bankDetails && hatchery.bankDetails[0].updateRequestFlag === "ApprovalPending"} />
                  {/* bank 2 */}
                  <BankDetails title="Bank Details 2"
                    id={2}
                    onSave={saveBankAccounts}
                    isLoading={isLoading}
                    details={1 in hatchery.bankDetails ? 
                      (hatchery.bankDetails[1].updateRequestDetails ? hatchery.bankDetails[1].updateRequestDetails : hatchery.bankDetails[1]) : 
                      {}
                    }
                    isPending={1 in hatchery.bankDetails && hatchery.bankDetails[1].updateRequestFlag === "ApprovalPending"} />
                </Tab>
                <Tab eventKey="licences" style={{ margin: 20 }} title="License Details" >
                  <Licenses details={hatchery} onLicenseUpload={updateHatcheryLicense} />
                </Tab>
              </Tabs>

              <FaceRecognition
                showCam={webCamForUserPic}
                profileType="owner"
                onCaptureCancel={() => { setWebCamForUserPic(false) }}
                onScanComplete = { (image, profileType) => {
                  setUserPic(image);
                  setWebCamForUserPic(false);
                }}
              />
            </>
          </div>
        </CardBody>
      </Card >
    </>
  );
}

export default connect(null, auth.actions)(PersonaInformation);
