import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Webcam from "react-webcam";
import moment from 'moment';
import axios from "axios";
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

const FaceRecognition = props => {

    const webcamRef = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);

    const capture = React.useCallback(
        () => {
            //return base64 string of captured image
            const imageSrc = webcamRef.current.getScreenshot();
            setImageSrc(imageSrc);
            props.onScanComplete(imageSrc, props.profileType);
        },
        [webcamRef]
    );
    
    return (
        <Modal show={props.showCam}>
            <Modal.Body>
                <div >
                    <div>
                        <div className="d-flex justify-content-center">
                            <Webcam
                                audio={false}
                                height={360}
                                ref={webcamRef}
                                screenshotFormat="image/png"
                                width={400}
                                videoConstraints={videoConstraints}
                            />
                        </div>
                        <div className="d-flex justify-content-end px-9">
                            <Button
                                className={`btn btn-primary font-weight-bold px-8 py-4 my-2`}
                                disabled={loading}
                                onClick={!loading ? capture : null}>
                                <span>Scan Face</span>
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </Button>
                            <Button style={{ marginLeft: '6%' }} className={`btn font-weight-bold px-8 py-4 my-2`} onClick={() => props.onCaptureCancel(false)} >Cancel</Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default FaceRecognition;