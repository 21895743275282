

export const bankNames = [
    { bankName: 'Allahabad bank' },
    { bankName: 'Andhra Bank' },
    { bankName: 'Axis Bank Ltd' },
    { bankName: 'Bandhan Bank Ltd' },
    { bankName: 'Bank of Baroda' },
    { bankName: 'Bank of India' },
    { bankName: 'Bank of Maharashtra' },
    { bankName: 'Canara Bank' },
    { bankName: 'Catholic Syrian Bank' },
    { bankName: 'Central Bank of India' },
    { bankName: 'City Union Bank Ltd' },
    { bankName: 'Corporation Bank' },
    { bankName: 'DBS Bank' },
    { bankName: 'Deutsche bank' },
    { bankName: 'Development Credit Bank' },
    { bankName: 'Dhanlaxmi Bank Ltd' },
    { bankName: 'Federal Bank Ltd' },
    { bankName: 'HDFC Bank Ltd' },
    { bankName: 'ICICI Bank Ltd' },
    { bankName: 'IDBI Bank Ltd' },
    { bankName: 'IDFC First Bank Ltd' },
    { bankName: 'Indian Bank' },
    { bankName: 'Indian Overseas Bank' },
    { bankName: 'Induslnd Bank Ltd' },
    { bankName: 'Jammu & Kashmir Bank Ltd' },
    { bankName: 'Karnataka Bank Ltd' },
    { bankName: 'Karur Vysya Bank Ltd' },
    { bankName: 'Kotak Mahindra Bank Ltd' },
    { bankName: 'Lakshmi Vilas Bank Ltd' },
    { bankName: 'Nainital Bank Ltd' },
    { bankName: 'Oriental Bank of Commerce' },
    { bankName: 'Punjab & Sind Bank' },
    { bankName: 'Punjab National Bank' },
    { bankName: 'RBL Bank Ltd' },
    { bankName: 'South Indian Bank Ltd' },
    { bankName: 'Standard chartered Bank' },
    { bankName: 'State Bank of India' },
    { bankName: 'Syndicate Bank' },
    { bankName: 'Tamilnad Mercantile Bank Ltd' },
    { bankName: 'UCO Bank' },
    { bankName: 'Union Bank of India' },
    { bankName: 'United Bank of India' },
    { bankName: 'Vijaya Bank' },
    { bankName: 'YES Bank Ltd' },

]