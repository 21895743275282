import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Figure } from 'react-bootstrap';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar, Input
} from "../../../../_websoc/_partials/controls";
import { makeStyles } from '@material-ui/styles';
import { Avatar, colors } from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import FaceRecognition from "./FaceRecognition";
import swal from "sweetalert";
import * as Yup from 'yup';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    greenAvatar: {
        margin: 0,
        color: '#fff',
        size: 100,
        backgroundColor: colors.blue[500],
    },
}));

const AuthorizedPersonDetails = (props) => {

    const classes = useStyles();
    const [userDetails, setUserDetails] = useState(props.details ? props.details : { name: '', email: '', phoneNumber: '', aadhaarNumber: '' })
    const [showCam, setShowCam] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);

    const validateData = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        phoneNumber: Yup.string().min(10).max(10).required('Required'),
        aadhaarNumber: Yup.number().required('Required'),
        picture: Yup.string().required('Required'),
    });

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={userDetails}
                validationSchema={validateData}
                onSubmit={(values) => {
                    props.onSave(values, imageSrc)
                }}>
                {({ handleSubmit }) => (
                    <Form className="form form-label-right">
                        <Card>
                            <CardHeader title={props.title}>
                                <CardHeaderToolbar>
                                    <button
                                        type="submit"
                                        disabled={props.isPending || props.isLoading}
                                        className="btn btn-primary ml-2" >
                                        {
                                            props.isPending
                                                ?
                                                'Approval Pending'
                                                :
                                                <><span>Save</span>{props.isLoading && <span className="ml-3 mr-3 spinner spinner-white"></span>}</>
                                        }
                                    </button>
                                </CardHeaderToolbar>
                            </CardHeader>
                            <CardBody>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        {
                                            ((userDetails && userDetails.picture) || imageSrc) ?
                                                <Figure>
                                                    <Figure.Image name="picture" src={imageSrc ? imageSrc : process.env.REACT_APP_ATTACHMENT_URL + userDetails.picture} width="171" height="180" style={{ border: 'solid 1px #E4E6EF', borderRadius: '0.42em' }} onClick={() => {
                                                        if (!props.isPending)
                                                            setShowCam(true)
                                                    }} />
                                                </Figure>
                                                :
                                                <Avatar className={classes.greenAvatar}>
                                                    <AddPhotoIcon onClick={() => setShowCam(true)} />
                                                </Avatar>
                                        }
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <Field
                                            disabled={props.isPending}
                                            name="name"
                                            required
                                            component={Input}
                                            placeholder="Name"
                                            label="Name"
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <Field
                                            disabled={props.isPending}
                                            name="phoneNumber"
                                            required
                                            component={Input}
                                            placeholder="Phone Number"
                                            label="Phone Number"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <Field
                                            disabled={props.isPending}
                                            name="email"
                                            required
                                            component={Input}
                                            placeholder="Email"
                                            label="Email"
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <Field
                                            disabled={props.isPending}
                                            name="aadhaarNumber"
                                            component={Input}
                                            placeholder="Aadhar Number"
                                            label="Aadhar Number"
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Form>
                )}
            </Formik>
            <FaceRecognition
              showCam={showCam}
              profileType= { props.title === "Authenticated Person 1" ? "auth1" : "auth2" }
              onCaptureCancel={() => { setShowCam(false) }}
              onScanComplete={(imageSrc, profileType) => { setShowCam(false); setImageSrc(imageSrc) }}
            />
        </>
    );
}

export default AuthorizedPersonDetails