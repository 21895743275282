import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { shallowEqual, useSelector } from "react-redux";


export default function PaymentInfoDialog(props) {

    const hatcheryDetails = useSelector((state) => state.dashboardData.hatcheryDetails, shallowEqual);



    return (
        <div>
            <Modal
                show={props.show}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>  Bank Details </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <div style={{ "height": "350px", "overflow-y": "scroll" }}>

                        <p>{props.infoMessage}</p>

                        {
                            hatcheryDetails.rzrpayVirtualAccount && hatcheryDetails.rzrpayVirtualAccount.receivers[0] &&
                            <>
                                <p> Benificiary Name : {hatcheryDetails.rzrpayVirtualAccount.receivers[0].name}</p>
                                {/* <p> Bank Name : {hatcheryDetails.rzrpayVirtualAccount.receivers[0].bank_name}</p> */}
                                <p> Account Number : {hatcheryDetails.rzrpayVirtualAccount.receivers[0].account_number}</p>
                                <p> IFSC : {hatcheryDetails.rzrpayVirtualAccount.receivers[0].ifsc}</p>
                            </>
                        }
                        <div><hr></hr></div>
                        <div style={{ justifyContent: 'center', display: 'flex' }}>
                            <h6>Payment Terms</h6>
                        </div>
                        <div style={{ marginTop: '3%' }}>

                            <p>
                                1.    This virtual account number can be used only for electronic funds transfer to provided account number through intra bank Funds Transfer or RTGS / NEFT/ IMPS.
                            </p>
                            <p>
                                2.    It is remitter's responsibility to remit the funds to correct account no. with correct amount as provided above. Incase of any mismatch in account no or amount, the transaction will be rejected and funds will be refunded back to remitter account by next working day.
                            </p>

                            <p>
                                3.     Please read IFSC (Indian Financial System Code) 5th character as 0 (zero) for this virtual account payment.
                            </p>
                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.hide}>
                        Ok
                    </Button>
                    {/* <Button variant="primary" onClick={acceptUnderTaking} >Accept</Button> */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}
