/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        {/*end::1 Level*/}




        {/*begin::1 Level*/}
        {/*end::1 Level*/}

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/Bookings", true
          )}`}
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/Bookings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/menu/booking.svg")} />
            </span>
            <span className="menu-text">Bookings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent">
                <span className="menu-link">
                  <span className="menu-text">Bookings</span>
                </span>
              </li>

              {/*begin::2 Level*/}

              <li
                className={`menu-item ${getMenuItemActive(
                  "/bookings/new"
                )}`}
              >
                <NavLink className="menu-link" to="/bookings/new">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Book Now</span>
                </NavLink>
              </li>


              <li
                className={`menu-item ${getMenuItemActive(
                  "/bookings/list"
                )}`}
              >
                <NavLink className="menu-link" to="/bookings/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Present Bookings</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/past-bookings/list"
                )}`}
              >
                <NavLink className="menu-link" to="/past-bookings/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Past Bookings</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>


        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/transactions/list", true
          )}`}
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/transactions/list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/menu/transaction.svg")} />
            </span>
            <span className="menu-text">Transactions</span>
          </NavLink>

        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/additional-charges/list", true
          )}`}
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/additional-charges/list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/menu/transaction.svg")} />
            </span>
            <span className="menu-text">Additional Charges</span>
          </NavLink>

        </li>

        {/*end::1 Level*/}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/payment-receipts/list", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/payment-receipts/list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/menu/receipt.svg")} />
            </span>
            <span className="menu-text">Payment Receipts</span>
          </NavLink>

        </li> */}

        {/*end::1 Level*/}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/Reports/list", true
          )}`}
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/Reports/list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/menu/reports.svg")} />
            </span>
            <span className="menu-text">Reports</span>
          </NavLink>

        </li>

        {/*end::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/hatchery-details", false)}`}
        >
          <NavLink className="menu-link" to="/hatchery-details">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/menu/hatchery.svg"
                )}
              />
            </span>
            <span className="menu-text">Hatchery Details</span>
          </NavLink>
        </li>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/how-to-use", true
          )}`}
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/how-to-use">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/menu/transaction.svg")} />
            </span>
            <span className="menu-text">How To Use</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">User Profile</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
