import axios from 'axios';
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as _ from 'lodash';
import { Figure, Image } from 'react-bootstrap';
import * as Yup from 'yup';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar, Input, Select
} from "../../../../_websoc/_partials/controls";

const ImageOrDocument = (props) => {
    const parts = props.document.split(".");
    const ext = parts[parts.length - 1];
    if (['jpeg', 'png', 'jpg', 'gif'].includes(ext)) {
        return (
            <a href={`${process.env.REACT_APP_ATTACHMENT_URL}${props.document}`} target="blank" >
                <Image src={`${process.env.REACT_APP_ATTACHMENT_URL}${props.document}`} rounded height="96px" width="120px" />
            </a>
        );
    } else {
        return (
            <a href={`${process.env.REACT_APP_ATTACHMENT_URL}${props.document}`} target="blank" download title="Click to View Document" >
                <Image src={process.env.PUBLIC_URL + "/media/misc/document.png"} rounded height="96px" width="120px" />
            </a>
        );
    }
};

const BankDetails = (props) => {

    const [bankDetails] = useState(props.details);
    const [proof, setProof] = useState(undefined);

    const handleChequeUpload = (document) => {
        setProof(document);
    }

    const fileUpload = async (document) => {
        try {
            let fileNameSplit = document.name.split('.');
            let fileName = `bank_proof_${props.id}.${_.last(fileNameSplit)}`
            var formdata = new FormData();
            formdata.append("files", document, fileName);
            const response = await axios.post('upload', formdata)
            return response.data[0].url;
        } catch (error) {
            throw error;
        }
    }

    const onSubmit = async (values) => {
        if (proof) {
            const dataURL = await fileUpload(proof);
            values.bankAccountDetailProof = dataURL;
        }
        props.onSave(values);
    }

    const validateData = Yup.object().shape({
        accountName: Yup.string().required('Required'),
        accountNumber: Yup.string().required('Required'),
        accountType: Yup.string().required('Required'),
        bankName: Yup.string().required('Required'),
        branchName: Yup.string().required('Required'),
        IFSC: Yup.string().required('Required'),
    });

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={bankDetails}
                validationSchema={validateData}
                onSubmit={(values) => {
                    onSubmit(values);
                }}>
                {({ handleSubmit, handleChange }) => (
                    <Form className="form form-label-right">
                        <Card>
                            <CardHeader title={props.title}>
                                <CardHeaderToolbar>
                                    <button
                                        type="submit"
                                        disabled={props.isPending || props.isLoading}
                                        className="btn btn-primary ml-2" >
                                        {props.isPending ? 'Approval Pending' : <><span>Save</span>{props.isLoading && <span className="ml-3 spinner spinner-white"></span>}</>}
                                    </button>
                                </CardHeaderToolbar>
                            </CardHeader>
                            <CardBody>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <Field
                                            disabled={props.isPending || props.isLoading}
                                            name="accountName"
                                            component={Input}
                                            label="Account Name"
                                            placeholder="Account Name"
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <Field
                                            disabled={props.isPending || props.isLoading}
                                            name="accountNumber"
											type="number"
                                            component={Input}
                                            label="Account Number"
                                            placeholder="Account Number"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <Field
                                            disabled={props.isPending || props.isLoading}
                                            name="accountType"
                                            label="Account Type"
                                            as={Select}
                                            onChange={(event) => handleChange(event)}
                                            placeholder="Account Type"
                                        >
                                            <option id="nothing" value={''} >Select Account Type</option>
                                            <option id="corporate" value="CORPORATE">Corporate</option>
                                            <option id="personal" value="PERSONAL">Personal</option>
                                        </Field>
                                    </div>
                                    <div className="col-lg-4">
                                        <Field
                                            disabled={props.isPending || props.isLoading}
                                            name="bankName"
                                            component={Input}
                                            label="Issuing Bank"
                                            placeholder="Issuing Bank"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <Field
                                            disabled={props.isPending || props.isLoading}
                                            name="branchName"
                                            component={Input}
                                            label="Branch Name"
                                            placeholder="Branch Name"
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <Field
                                            disabled={props.isPending || props.isLoading}
                                            name="IFSC"
                                            component={Input}
                                            label="IFSC"
                                            placeholder="IFSC"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <span style={{ marginTop: '50px' }}>Bank Proof</span>
                                        <br />
                                        {
                                            bankDetails.bankAccountDetailProof
                                                ?
                                                <ImageOrDocument document={bankDetails.bankAccountDetailProof} />
                                                :
                                                <input disabled={props.isPending || props.isLoading} type="file"
                                                    name="bankAccountDetailProof"
                                                    style={{ marginTop: '32px' }}
                                                    required
                                                    accept=".png, .jpg, .jpeg"
                                                    onChange={(event) => {
                                                        handleChequeUpload(event.currentTarget.files[0]);
                                                    }}
                                                />
                                        }
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Form>
                )}
            </Formik>
            
        </>
    );
}

export default BankDetails