import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Formik, Form, Field } from "formik";
import * as actions from "../../../_redux/bookings/bookingsActions";
import { Input, Select } from "../../../../../../_websoc/_partials/controls";
import { Calendar as BigCalendar, momentLocalizer, Views } from "react-big-calendar";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import moment from 'moment';
import axios from 'axios';
import * as actionsDashboard from "../../../../DashboardAPI/dashboardActions";
import swal from 'sweetalert';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { makeStyles } from '@material-ui/core/styles';
import { green, pink, blue, gray } from '@material-ui/core/colors'
import Avatar from '@material-ui/core/Avatar';



const localizer = momentLocalizer(moment);
const viewsCustom = { MONTH: "month" }
let allViews = Object.keys(Views).map(k => viewsCustom[k])


const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })




const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  avatar: {
    margin: 0,
  },
  pinkAvatar: {
    margin: 0,
    color: '#fff',
    backgroundColor: pink[500],
  },
  greenAvatar: {
    margin: 0,
    color: '#fff',
    size: 100,
    backgroundColor: blue[500],
  },
}));

export function BookingCreateForm({
  booking,
  btnRef,
  saveBooking,
}) {

  const classes = useStyles();

  const [showTerms, setShowTerms] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [declineTerms, setDeclineTerms] = useState(false);
  const [termsType, setTermsType] = useState('');
  const [bookingRequestBody, setBookingRequestBody] = useState();

  const hatchery = useSelector((state) => state.dashboardData.hatcheryDetails, shallowEqual);
  const maxImportAllowed = (hatchery.noOfBroodsStockPerYear - hatchery.fyImportedStock)
  const suppliers = useSelector((state) => state.bookings.suppliers, shallowEqual);
  const balanceBS = useSelector((state) => state.dashboardData.balanceBS, shallowEqual);
  const [yearForBS, setYearForBS] = useState(2021);

  const [showUnderTaking, setUnderTaking] = useState(false);

  const termsAndConditions = useSelector((state) => state.dashboardData.termsAndCondition, shallowEqual);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actionsDashboard.fetchAllBSPerYear(yearForBS));
  }, [yearForBS, dispatch]);

  const [cubicals, setCubicals] = useState([])
  const [terms, setTerms] = useState([])
  const [cubicalsAddLimit, setCubicalsAddLimit] = useState(0)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedCubical, setSelectedCubical] = useState([])
  const [enterTOtalNoOfImport, setEnterTOtalNoOfImport] = useState(null)
  const [EnterBiomass, setEnterBiomass] = useState(null)
  const [totalBiomass, setTotalBiomass] = useState(null)
  const [requiredCubicals, setRequiredCubicals] = useState(0)
  const [totalAmount, setTotalAmount] = useState(null)
  const [cubicalsAvailable, setCubicalsAvailable] = useState(0);
  const [cubicalsNotAvailable, setCubicalsNotAvailable] = useState(0);
  const [cubicalsBlocked, setCubicalsBlocked] = useState(0);
  const [gstAmount, setGSTAmount] = useState();


  const selectedEvent = (event) => {
    console.log(requiredCubicals, cubicalsAddLimit)
    setSelectedDate(event.start);


    if (requiredCubicals === 0 || EnterBiomass < 20) {

      swal("Failed", "Please enter Total Import Data and Biomass( Min 20gms)", "error");
    } else {
      if (event.color === "green") {

        if (selectedDate != null && event.start === selectedDate) {

          if (cubicalsAddLimit < requiredCubicals && cubicalsAddLimit < 2) {
            const b = selectedCubical
            b.push(event.scheduleId)
            setSelectedCubical(b);
            const a = cubicals;
            a.push(event)
            setCubicals(a)
            setCubicalsAddLimit(cubicalsAddLimit + 1)
          } else {
            swal("Failed", "You can only add " + requiredCubicals + "cubicles", "error");
          }
        } else {

          setCubicalsAddLimit(1)
          setSelectedCubical(cubicals);
          const a = [];
          const b = []
          a.push(event)
          setCubicals(a)
          b.push(event.scheduleId)
          setSelectedCubical(b);

        }
      } else {
        swal("Failed", "You can only select green colored cubicles ", "error");
      }
    }
  }

  const selectedMonth = (range) => {
    var today = new Date();
    var checkPresent = moment(today, 'YYYY/MM/DD');
    var monthPresent = checkPresent.format('MM');
    var yearPresent = checkPresent.format('YYYY');
    const startPresent = `${yearPresent}-${monthPresent}-01`;

    var check = moment(range, 'YYYY/MM/DD');
    var month = check.format('MM');
    var day = check.format('DD');
    var year = check.format('YYYY');
    let noOfDays = moment(`${year}-${month}`, "YYYY-MM").daysInMonth()
    setYearForBS(moment(range.start).format('YYYY'))
    if (month === monthPresent) {
      var start = `${year}-${month}-${day}`;
    } else {
      var start = `${year}-${month}-01`;
    }

    const currentDate = `${year}-${month}-01`;
    const end = `${year}-${month}-${noOfDays}`;
    const rangeObj = { start, end }
    if (startPresent <= currentDate) {
      dispatch(actions.fetchBookingByMonth(rangeObj, hatchery.permittedSpeciesId.id));
    } else {
      swal({ text: "Can not book for previous months" });
    }
  }

  const addNewEventAlert = (slotInfo) => {
    console.log(slotInfo)
  }

  const { bookingByMonthData } = useSelector(
    (state) => ({
      bookingByMonthData: state.bookings.bookingByMonthData,
    }),
    shallowEqual
  );

  const populateCubicleDetails = () => {

    setTotalBiomass((enterTOtalNoOfImport * EnterBiomass) / 1000)
    axios.get(`/bookings/populate-cubicle-details?bioMassPerStock=${EnterBiomass}&totalNumberOfStock=${enterTOtalNoOfImport}`)
      .then(({ data }) => {
        setRequiredCubicals(data.cubicleRequired)
        setTotalAmount(data.totalAmount)
        setGSTAmount(data.gst)
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          swal("Failed", err.response.data.message, "error");
        }
      });
  };

  const [events, setEvents] = useState([
    {
      title: "RGCA",
      start: "2021-01-20",
      end: "2021-01-20",
      color: "orange",
    }
  ]);

  const saveNewBooking = (values) => {
    const data = {
      "supplierId": values.suppliers,
      "cubicleBookingScheduleIds": selectedCubical,
      "bioMassPerStock": parseInt(EnterBiomass),
      "totalNumberOfStock": enterTOtalNoOfImport,
      // "bankAccountId": values.bankaccounts
    }

    saveBooking(data)
  }


  useEffect(() => {
    if (bookingByMonthData) {
      if (bookingByMonthData.schedules.length === 0) {
        swal({ text: "Bookings are not yet open for selected month", })

      }
      if (bookingByMonthData.status !== "Blocked") {
        setCubicalsAvailable(0);
        setCubicalsNotAvailable(0);
        setCubicalsBlocked(0);
        const eventNewList = [];
        bookingByMonthData.schedules.map(cubicle => {

          if (cubicle.status === "Available" && cubicle.speciesId.name === hatchery.permittedSpeciesId.name) {
            setCubicalsAvailable(cubicalsAvailable + 1);
            eventNewList.push({
              title: cubicle.cubicleId.id,
              scheduleId: cubicle.id,
              start: cubicle.availableDate,
              end: cubicle.availableDate,
              // color: "orange",
              color: "green",
            })
          }
          if (cubicle.status === "Blocked" && cubicle.speciesId.name === hatchery.permittedSpeciesId.name) {
            setCubicalsNotAvailable(cubicalsNotAvailable + 1);
            eventNewList.push({
              title: cubicle.cubicleId.id,
              // id:cubicle.cubicleId.id,
              scheduleId: cubicle.id,
              start: cubicle.availableDate,
              end: cubicle.availableDate,
              // color: "orange",
              color: "yellow",
            })
          }
          if (cubicle.status === "Confirmed" && cubicle.speciesId.name === hatchery.permittedSpeciesId.name) {
            setCubicalsBlocked(cubicalsBlocked + 1);
            eventNewList.push({
              title: cubicle.cubicleId.id,
              // id:cubicle.cubicleId.id,
              scheduleId: cubicle.id,
              start: cubicle.availableDate,
              end: cubicle.availableDate,
              // color: "orange",
              color: "red",
            })
          }
        })
        setEvents(eventNewList)
      } else {
        swal({ text: "Cubicle booking is closed for this month", });
      }
    }

  }, [bookingByMonthData]);

  const handleTotalNoOfImport = (data) => {
    if (data > maxImportAllowed) {
      swal(`Only ${maxImportAllowed} items are left to import for this year!`)
      setEnterTOtalNoOfImport(maxImportAllowed)
    } else {
      setEnterTOtalNoOfImport(data)
    }
    setSelectedCubical([])
    setCubicalsAddLimit(0)
    setCubicals([])
  }

  const handleBiomass = (data) => {
    setEnterBiomass(data)
  }

  const eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  }

  useEffect(() => {
    var today = new Date();
    var checkPresent = moment(today, 'YYYY/MM/DD');
    var monthPresent = checkPresent.format('MM');
    var dayPresent = checkPresent.format('DD');
    var yearPresent = checkPresent.format('YYYY');

    let noOfDays = moment(`${yearPresent}-${monthPresent}`, "YYYY-MM").daysInMonth()
    // setYearForBS(moment(range.start).format('YYYY'))
    const start = `${yearPresent}-${monthPresent}-${dayPresent}`;
    const end = `${yearPresent}-${monthPresent}-${noOfDays}`;
    const rangeObj = { start, end }
    dispatch(actions.fetchBookingByMonth(rangeObj, hatchery.permittedSpeciesId.id));
    //hatchery.permittedSpeciesId.id
    dispatch(actions.fetchSuppliers(hatchery.permittedSpeciesId.id));

  }, [dispatch]);

  useEffect(() => {
    if (enterTOtalNoOfImport != null && EnterBiomass != null && EnterBiomass >= 20)
      populateCubicleDetails(enterTOtalNoOfImport, EnterBiomass)
  }, [enterTOtalNoOfImport, EnterBiomass, dispatch]);


  const agreeToTerms = () => {
    setShowTerms(false)
    setUnderTaking(true)
  }


  const acceptUnderTaking = () => {
    setAgreeTerms(true)
    setUnderTaking(false)

    // API for Booking
    saveNewBooking(bookingRequestBody)

  }




  const declineToTerms = () => {
    setDeclineTerms(true)
    setShowTerms(false)
    setUnderTaking(false)
  }

  return (
    <>

      <Formik
        enableReinitialize={true}
        initialValues={booking}
        //  validationSchema={BookingEditSchema}
        onSubmit={(values) => {
          setShowTerms(true)
          setBookingRequestBody(values)
        }}
      >
        {({ handleSubmit, handleChange }) => (
          <>

            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    disabled
                    name="speciesType"
                    value={hatchery.permittedSpeciesId.name || ''}
                    component={Input}
                    placeholder={hatchery.permittedSpeciesId.name || ''}
                    label="Species Type"
                  />
                </div>
                <div className="col-lg-4">
                  <Select name="suppliers" label=" Select Supplier" required>
                    <option key="" value="">Select ....</option>
                    {suppliers &&
                      suppliers.map((supplier) => (
                        <option key={supplier.id} value={supplier.id}>
                          {supplier.name}
                        </option>
                      ))}

                  </Select>
                </div>
                {/* <div className="col-lg-4">
                  <Select name="bankaccounts" label="Select Bank Account" required>
                    <option key="" value="">Select ....</option>
                    {hatchery.bankDetails &&
                      hatchery.bankDetails.map((account) => (
                        <option key={account.id} value={account.id}>
                          {`${account.bankName} - ${account.accountNumber}`}
                        </option>
                      ))}
                  </Select>
                </div> */}
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  {/* <small>* should not be more than remaining limit</small> */}
                  <Field
                    required
                    type="number"
                    max={maxImportAllowed}
                    name="EnterTOtalNoOfImport"
                    value={enterTOtalNoOfImport}
                    component={Input}
                    min={0}
                    onChange={(event) => {
                      handleTotalNoOfImport(event.currentTarget.value)
                    }}
                    placeholder="Enter Total No Of Import"
                    label="Enter Total No Of Import"
                  />
                </div>
                {/* only for speciesId l vana */}
                {hatchery.permittedSpeciesId.id !== 3 &&
                  <div className="col-lg-4">
                    <Field
                      type='number'
                      name="EnterBiomass"
                      component={Input}
                      placeholder="Enter Biomass"
                      onChange={(e) => { handleBiomass(e.currentTarget.value) }}
                      label="Enter Biomass for 1 stock in gms"
                    />
                  </div>
                }
                <div className="col-lg-4">
                  <Field
                    readOnly
                    name="totalBiomass"
                    component={Input}
                    placeholder={totalBiomass}
                    label="Total Biomass in KG's"
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    readOnly
                    value={''}
                    component={Input}
                    placeholder={totalAmount - gstAmount > 0 ? totalAmount - gstAmount : 0}
                    label="Premium Cost"

                  />
                </div>

                <div className="col-lg-4">
                  <Field
                    readOnly
                    value={''}
                    component={Input}
                    placeholder={gstAmount - 0}
                    label="GST Amount"

                  />
                </div>

                <div className="col-lg-4">
                  <Field
                    readOnly
                    name="totalCost"
                    component={Input}
                    placeholder={totalAmount}
                    label="Total Cost"
                    required
                  />
                </div>

              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    readOnly
                    name="selectedDate"
                    component={Input}
                    placeholder={selectedDate}
                    label="Selected Date"
                    required
                  />
                </div>
                {cubicals.length > 0 ? (
                  cubicals.map((cubs) => (
                    <div className="col-lg-4">
                      <Field
                        readOnly
                        name={cubs.title}
                        component={Input}
                        placeholder={cubs.title}
                        label="Selected Cubicle"
                        required
                      />
                    </div>
                  ))
                ) : undefined}

                <div className="col-lg-4">
                  <Field
                    readOnly
                    type="number"
                    name="RequiredCubicle"
                    component={Input}
                    placeholder={requiredCubicals}
                    label="Required Cubicles"
                  />
                </div>

              </div>
              <div className="form-group row">
                {/*  */}
              </div>
              <div className="form-group row" style={{ height: "500px", width: "800px" }}>

                <BigCalendar
                  events={events}
                  views={allViews}
                  step={60}
                  showMultiDayTimes
                  // max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
                  style={{ height: "500px", width: "800px" }}
                  components={{
                    timeSlotWrapper: ColoredDateCellWrapper,
                  }}
                  popup
                  selectable
                  localizer={localizer}
                  defaultView="month"
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={(event) => selectedEvent(event)}
                  onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
                  onNavigate={(range) => selectedMonth(range)}
                  eventPropGetter={
                    (event, start, end, isSelected) => {
                      let newStyle = {
                        backgroundColor: "green",
                        color: 'white',
                        borderRadius: "0px",
                        border: "none"
                      };
                      if (event.color === "red") {
                        newStyle.backgroundColor = "red"
                      }
                      if (event.color === "green") {
                        newStyle.backgroundColor = "green"
                      }
                      if (event.color === "yellow") {
                        newStyle.backgroundColor = "yellow";
                        newStyle.color = "black"
                      }
                      return {
                        className: "",
                        style: newStyle
                      };
                    }
                  }
                />
              </div>

              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}

                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>


      <Modal
        show={showTerms}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* //"overflow-y": "scroll" */}
          <div style={{ "height": "100px", }}>


            {/* {termsAndConditions &&
              termsAndConditions[0].JSONvalue.TERMS_CONDITIONS.map((term, index) => (
                <>
                  <p>{`${index + 1} ${'.    '} ${term}`}</p> <br></br>
                </>
              ))} */}

            <div className="form-group row" style={{ display: 'flex', justifyContent: 'center' }}>

              <div className="col-lg-4">
                {termsAndConditions && <>
                  <label>PRIVACY POLICY</label>

                  <a href={process.env.REACT_APP_ATTACHMENT_URL + termsAndConditions[0].JSONvalue.PRIVACY_POLICY.url} target='_blank'>
                    <Avatar className={classes.greenAvatar}>
                      <AssignmentIcon />
                    </Avatar>
                  </a>
                </>}
              </div>

              <div className="col-lg-4">
                {termsAndConditions && <>
                  <label>TERMS CONDITIONS</label>

                  <a href={process.env.REACT_APP_ATTACHMENT_URL + termsAndConditions[0].JSONvalue.TERMS_CONDITIONS.url} target='_blank'>
                    <Avatar className={classes.greenAvatar}>
                      <AssignmentIcon />
                    </Avatar>
                  </a>
                </>}
              </div>

            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={declineToTerms}>
            Decline
          </Button>
          <Button variant="primary" onClick={agreeToTerms} >Accept</Button>
        </Modal.Footer>
      </Modal>



      <Modal
        show={showUnderTaking}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>UNDERTAKING LETTER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* style={{ "height": "350px", "overflow-y": "scroll" }} */}
          <div >

            <p>
              {
                termsAndConditions[0].JSONvalue.UNDERTAKING_LETTER
              }
            </p>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={declineToTerms}>
            Decline
          </Button>
          <Button variant="primary" onClick={acceptUnderTaking} >Accept</Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}