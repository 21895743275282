import * as requestFromServer from "./dashboardCrud";
import { dashboardSlice, callTypes } from "./dashboardSlice";

const { actions } = dashboardSlice;

export const fetchUserRoleAccess = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUserRolesAndAccess(queryParams.role.id)
    .then(response => {
      const totalCount = response.data;
      dispatch(actions.fetchedUserRoleAccess({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Booking";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBooking = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllBookingsCount(queryParams)
    .then(response => {
      const totalCount = response.data;
      dispatch(actions.fetchedBooking({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Booking";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchNotifications = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getNotifications(queryParams)
    .then(response => {
      const entities = response.data;
      const totalCount = response.data.length;
      dispatch(actions.fetchedNotifications({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Booking";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const fetchTermsAndCondition = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getTermsAndConditions()
    .then(response => {
      const entities = response.data;
      const totalCount = response.data.length;
      dispatch(actions.fetchedTermsAndCondition({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Booking";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const fetchAllBSPerYear = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllBSPerYear(queryParams)
    .then(response => {
      console.log(response, "response");

      const totalCount = response.data;
      dispatch(actions.fetchedAllBSPerYear({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Booking";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const fetchHatcheryDetails = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getHatcheryDetails(queryParams)
    .then(response => {
      const data = response.data;
      dispatch(actions.fetchedHatcheryDetails({ data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find details";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBookingsForCC = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllBookings(queryParams)
    .then(response => {
      const entities = response.data;
      const totalCount = response.data.length;
      dispatch(actions.fetchedBookingsForCC({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Booking";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBookingsForTransactions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllBookingsForTransactions(queryParams)
    .then(response => {
      const entities = response.data;
      const totalCount = response.data.length;
      dispatch(actions.fetchedBookingsForTransactions({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Booking";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};



export const fetchLARCount = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLARCount(queryParams)
    .then(response => {
      const totalCount = response.data;
      dispatch(actions.fetchedLARCount({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchHatcheriesCount = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getHatcheriesCount(queryParams)
    .then(response => {
      const totalCount = response.data;
      dispatch(actions.fetchedHatcheriesCount({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchReports = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getReports(queryParams)
    .then(response => {
      const totalCount = response.data.length;
	  dispatch(actions.fetchedReports({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchTotalVsOpenBookings = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getTotalVsOpenBookings(queryParams)
    .then(response => {
      const totalCount = response.data;
      dispatch(actions.fetchedTotalVsOpenBookings({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchTotalVsOpenLAR = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getTotalVsOpenLAR(queryParams)
    .then(response => {
      const totalCount = response.data.length;
      dispatch(actions.fetchedTotalVsOpenLAR({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


