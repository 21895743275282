import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Figure, Image } from 'react-bootstrap';
import * as _ from 'lodash';
import swal from "sweetalert";
import {
    Card,
    CardBody,
    Input
} from "../../../../_websoc/_partials/controls";


const ImageOrDocument = (props) => {
    const parts = props.document.split(".");
    const ext = parts[parts.length - 1];
    console.log('ext', ext);
    if (['jpeg', 'png', 'jpg', 'gif'].includes(ext)) {
        return (
            <a href={`${process.env.REACT_APP_ATTACHMENT_URL}${props.document}`} target="blank" >
                <Image src={`${process.env.REACT_APP_ATTACHMENT_URL}${props.document}`} rounded height="96px" width="120px" />
            </a>
        );
    } else {
        return (
            <a href={`${process.env.REACT_APP_ATTACHMENT_URL}${props.document}`} target="blank" download title="Click to View Document" >
                <Image src={process.env.PUBLIC_URL + "/media/misc/document.png"} rounded height="96px" width="120px" />
            </a>
        );
    }
};

const Licenses = (props) => {

    const [details] = useState(props.details);
    const [file, setFile] = useState(undefined);

    const handleLicenseUpload = async () => {
        try {
            if (file) {
                var formdata = new FormData();
                let fileNameSplit = file.name.split('.');
                let fileName = `license_.${_.last(fileNameSplit)}`
                formdata.append("files", file, fileName);
                const fileUploadResponse = await axios.post('upload', formdata);
                props.onLicenseUpload(fileUploadResponse.data[0])
            } else {
                swal("Please choose license file to upload", "", "info")
            }
        } catch (error) {
            if (error.response)
                swal(error.response.data.message, "", "error")
            else
                swal(error.message, "", "error")
        }
    }

    return (
        <Card>
            <CardBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={details} onSubmit={(values) => {
                        console.log(values)
                    }}>
                    <>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <Field
                                    disabled
                                    name="validFrom"
                                    component={Input}
                                    placeholder="valid From"
                                    label="Valid From"
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    disabled
                                    name="validTo"
                                    component={Input}
                                    placeholder="valid To"
                                    label="Valid To"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <Field
                                    disabled
                                    name="CAARegistrationNumber"
                                    component={Input}
                                    placeholder="CAA Registration Number"
                                    label="CAA Registration Number"
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    disabled
                                    name="noOfBroodsStockPerYear"
                                    component={Input}
                                    placeholder="Max Permit Limit"
                                    label="Max Permit Limit"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                {
                                    details && details.licence
                                        ?
                                        <ImageOrDocument document={details.licence} />
                                        :
                                        details && details.updateRequestDetails && details.updateRequestDetails.licence ?
                                            <span>* Licence uploaded and pending for Approval.</span>
                                        :    
                                        <Form className="form form-label-right">
                                            <input type="file" required onChange={(event) => {
                                                setFile(event.currentTarget.files[0]);
                                            }} /> 
                                            <button type="button" className="btn btn-primary" onClick={handleLicenseUpload}>Upload</button>
                                        </Form>
                                }
                            </div>
                        </div>
                    </>
                </Formik>
            </CardBody>
        </Card >
    );
}

export default Licenses;