// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import axios from 'axios';
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { shallowEqual, useSelector } from "react-redux";
import Webcam from "react-webcam";
import swal from 'sweetalert';
import { Select } from "../../../../../../_websoc/_partials/controls";



export const FACEMATCH_URL = 'settings/facematch';


export function BeforeBooking({
  setTab,
}) {
  const hatchery = useSelector((state) => state.dashboardData.hatcheryDetails, shallowEqual);

  const termsAndConditions = useSelector((state) => state.dashboardData.termsAndCondition, shallowEqual);


  const [facialRecognitionSuccess, setFacialRecognitionSuccess] = useState(false)
  const [profileSelectedForFaceRecg, setprofileSelectedForFaceRecg] = useState(null);
  const [userProfilesForFaceRecg, setUserProfilesForFaceRecg] = useState([])
  const [loader, setLoader] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [openCamera, setOpenCamera] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [faceScaning, setFaceScaning] = useState(false);


  const hideUnderTaking = () => {
    setFaceScaning(true)
  }

  useEffect(() => {

    let arrayOfProfs = []

    if (hatchery.userProfile.picture) {
      arrayOfProfs.push({
        name: hatchery.userProfile.name,
        picture: hatchery.userProfile.picture
      });
    }

    if (hatchery.userProfile.authPerson1 && hatchery.userProfile.authPerson1.picture)
      arrayOfProfs.push({
        name: hatchery.userProfile.authPerson1.name,
        picture: hatchery.userProfile.authPerson1.picture
      });

    if (hatchery.userProfile.authPerson2 && hatchery.userProfile.authPerson2.picture)
      arrayOfProfs.push({
        name: hatchery.userProfile.authPerson2.name,
        picture: hatchery.userProfile.authPerson2.picture
      });

    setUserProfilesForFaceRecg(arrayOfProfs)

  }, [hatchery]);



  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };



  const webcamRef = React.useRef(null);

  const capture = React.useCallback(
    () => {
      // setTab("advanced")

      setLoading(true);
      let compareImage = {};
      setLoader(true)
      let convertedBase64Data = "";
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc === null) {
        swal("Could not capture picture. Please scan properly", "Try Again", "error");
        setLoading(false);
      } else {
        let dataImage = imageSrc.toString().replace("\n", "");
        dataImage = dataImage.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '')
        if (profileSelectedForFaceRecg != null) {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", process.env.REACT_APP_ATTACHMENT_URL + profileSelectedForFaceRecg, true);
          xhr.responseType = "blob";
          xhr.onload = function (e) {
            var reader = new FileReader();
            reader.onload = function (event) {
              var res = event.target.result;
              console.log(res, 'Image')
              convertedBase64Data = res.toString().replace("\n", "")
              convertedBase64Data = convertedBase64Data.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '');

              compareImage = {
                "userid": "37",
                "imagedata1": convertedBase64Data,
                "imagedata2": dataImage
              };

              // Face Match
              axios.post(FACEMATCH_URL, compareImage)
                .then(({ data }) => {
                  if (data.status === 0) {
                    swal(data.errdesc, "Please try again !", "error")
                    setLoading(false);
                    convertedBase64Data = ''
                  } else {
                    setFacialRecognitionSuccess(true)
                    setTab("advanced")
                    convertedBase64Data = ''
                    setLoading(false);
                  }
                })
                .catch((err) => {
                  console.log(err)
                  convertedBase64Data = ''
                });

            }

            var file = this.response;
            reader.readAsDataURL(file)
          };
          xhr.send()
        }
      }
    },
    [setLoader, profileSelectedForFaceRecg, webcamRef]
  );

  return (
    <>

      <div style={{ border: 1 }}>
        <Formik>
          <div className="col-lg-4">
            {
              userProfilesForFaceRecg.length > 0
                ?
                <Select
                  name="userProfilesForFaceRecg"
                  label="User Profiles"
                  onChange={(event) => {
                    setprofileSelectedForFaceRecg(event.currentTarget.value)
                    setOpenCamera(true)
                  }}
                  required>
                  <option key="" value="">Select a user</option>
                  {
                    userProfilesForFaceRecg.map((profile) => (
                      <option key={profile.picture} value={profile.picture}>
                        {profile.name}
                      </option>
                    ))
                  }
                </Select>
                :
                <span>Please upload your profile picture or add auth persons details along with their picture, through face scan</span>
            }
          </div>
        </Formik>
        {openCamera &&

          <div>
            <div className="d-flex justify-content-start">
              <Webcam
                audio={false}
                height={350}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={400}
                videoConstraints={videoConstraints}
              />
            </div>
            <Button
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              disabled={isLoading}
              onClick={!isLoading ? capture : null}
            >
              <span>Scan Face</span>
              {isLoading && <span className="ml-3 spinner spinner-white"></span>}

            </Button>

          </div>

        }


      </div>

      {/* } */}



    </>


  );
}
