import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { fetchHatchery, resetPassword } from "../_redux/authCrud";

const initialValues = {
    password: "",
    confirmPassword: "",
    verificationCode: ""
};

export function ResetPassword(props) {
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    
    const location = useLocation();

    const ResetPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, "Minimum 8 symbols")
            .max(20, "Maximum 20 symbols")
            .required(
                "Password Required"
            ),
        confirmPassword: Yup.string()
            .min(8, "Minimum 8 symbols")
            .max(20, "Maximum 20 symbols")
            .required(
                "Password Confirmation Required"
            ),

        verificationCode: Yup.string()
            .min(4, "Minimum 4 symbols")
            .max(6, "Maximum 6 symbols")
            .required(
                "Verification Code Required"
            ),
    });
    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    
    const formik = useFormik({
        initialValues,
        validationSchema: ResetPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            resetPassword(values.password, values.confirmPassword, values.verificationCode)
                .then(({ data }) => {
                    disableLoading();
                    userOnBoardingProcess()
                })
                .catch((error) => {
                    setSubmitting(false);
                    setStatus(error.response.data.message);
                    disableLoading();
                    //userOnBoardingProcess()
                });
        },
    });

    const userOnBoardingProcess = () => {
        console.log(props.history, "()()()", props)
        props.history.push(`/auth/login`);
    };
    
    const statusMessage = formik.status ? formik.status : 
      (location.state && location.state.status) ? location.state.status : '';
      
    return (
        <div className="login-form login-forgot" style={{ display: "block" }}>
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">Reset Password ?</h3>
                {/* <div className="text-muted font-weight-bold">
                    Enter Your Registered Phone Number
                </div> */}
            </div>
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate_animated animate_backInUp"
            >
                {statusMessage && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {statusMessage}
                        </div>
                    </div>
                )}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Enter Password"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "password"
                        )}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Confirm Password"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "confirmPassword"
                        )}`}
                        name="confirmPassword"
                        {...formik.getFieldProps("confirmPassword")}
                    />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.confirmPassword}</div>
                        </div>
                    ) : null}
                </div>

                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="OTP Verification Code"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "verificationCode"
                        )}`}
                        name="verificationCode"
                        {...formik.getFieldProps("verificationCode")}
                    />
                    {formik.touched.verificationCode && formik.errors.verificationCode ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.verificationCode}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                    <button
                        id="kt_login_forgot_submit"
                        type="submit"
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        disabled={formik.isSubmitting}
                    >
                        Submit
                    </button>
                    <Link to="/auth/login">
                        <button
                            type="button"
                            id="kt_login_forgot_cancel"
                            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        >
                            Cancel
                        </button>
                    </Link>
                </div>
            </form>
        </div>
    )
}

//export default ResetPassword;