import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import swal from 'sweetalert';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  identifier: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [captchaValidation, setCaptchaValidation] = useState(false);

  const LoginSchema = Yup.object().shape({
    identifier: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Not a Valid Identifier"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Not a Valid Password")
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };



  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        if (captchaValidation === false) {
          setStatus('Captcha Required')
          disableLoading();
          setSubmitting(false)
        } else {
          login(values.identifier, values.password)
            .then(response => {
              console.log(response)
              if (response.status !== 200) {
                throw Error(response.statusText);
              }
              return response.data;
            })
            .then(data => {
              console.log(data)
              disableLoading();
              props.fulfillUser(data.user);
              props.login(data.jwt);
            }).catch((err) => {
              disableLoading();
              setSubmitting(false);

              if (err.response) {
                if (Array.isArray(err.response.data.data)) {
                  setStatus(err.response.data.data[0].messages[0].message);
                } else {
                  setStatus(err.response.data.message);
                }
              }
            });
        }
      }, 1000);
    },
  });

  function onChange(value) {

    let payload = {
      captcha: value
    }

    setCaptchaValidation(true)

    axios.post('/settings/verify-google-captcha', payload)
      .then(response => {
        setCaptchaValidation(true)
      })
      .catch(error => {
        console.log(error.response.status)
        if (error.response.status !== 200) {
          swal(error.response.statusText, '', 'error')
        }
      });
  }

  return (
    <>
      <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
        <span className="font-weight-bold text-white">Don't have an account yet?</span>
        <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup" style={{ color: "yellow" }}>Sign Up!</Link>
      </div>
      <div className="login-form login-signin" id="kt_login_signin_form" style={{ marginLeft: "8%" }}>
        {/* begin::Head */}
        <div className="text-center mb-10 mb-lg-20" style={{ marginLeft: "-25%", marginTop: "20%" }}>
          <h3 className="font-size-h1" style={{ color: "white" }}>
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
          <p className="text-muted font-weight-bold">
            Enter your Credentials
          </p>
        </div>
        {/* end::Head */}


        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
          style={{ marginTop: "-12%" }}
        >
          {formik.status ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (
            <div className="mb-10">
              {/* <div className="alert-text ">
              Use account <strong>admin@demo.com</strong> and password{" "}
              <strong>demo</strong> to continue.
            </div> */}
            </div>
          )}

          <div className="form-group fv-plugins-icon-container">
            <input
              style={{ width: "76%" }}
              autoComplete="off"
              placeholder="Identifier"
              type="identifier"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "identifier"
              )}`}
              name="identifier"
              {...formik.getFieldProps("identifier")}
            />
            {formik.touched.identifier && formik.errors.identifier ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.identifier}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              style={{ width: "76%" }}
              autoComplete="off"
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          <div style={{ marginBottom: "10px", marginLeft: "47%" }}>
            <Link
              to="/auth/forgot-password"
              className="text-white text-hover-primary my-3 mr-2"
              id="kt_login_forgot"
            >
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginLeft: "-2%", width: '80%' }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_PRODUCTION}
              onChange={onChange}
            />
          </div>


          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            {/* <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> */}
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span>Sign In</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div >
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <label style={{ color: "white" }}>Contact Us</label>
            </div>
            <div className="col-md-6">
              <label style={{ color: "white" }}>Terms and  Conditions</label>
            </div>
          </div>
        </form>

      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
