import axios from 'axios';
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { Select } from "../../../../../src/_websoc/_partials/controls";
import { fetchHatchery } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";


const initialValues = {
  CAARegistrationNumber: ""
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [showRegVideo, setShowRegVideo] = useState(false);
  const [unregisteredHatcheries, setUnregisteredHatcheries] = useState([]);
  const [caaNumber, setCAANumber] = useState(false);
  useEffect(() => {
    getUnregisteredCAA()
  }, [])


  async function getUnregisteredCAA() {
    try {
      await axios.get("/hatcheries?approvalStatus=ApprovalPending&userProfile_null=true&_limit=50")
        .then((result) => {
          setUnregisteredHatcheries(result.data)
        });
    } catch (error) {
      console.log(error) // catches both errors
    }
  }


  const enableLoading = () => {
    setLoading(true);
  };


  const disableLoading = () => {
    setLoading(false);
  };



  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };


  const onSubmit = () => {
    enableLoading();
    fetchHatchery(caaNumber)
      .then(({ data }) => {
        props.saveHatcheryDetails(data);
        disableLoading();
        userOnBoardingProcess()
      })
      .catch((err) => {
        swal(err.response.data.message, ' ', 'error')
        disableLoading();
      });
  }



  const userOnBoardingProcess = () => {
    console.log(props.history, "()()()", props)
    props.history.push(`/user-onboarding-process`);
  };

  return (
    <>
      <div style={{ color: 'yellow' }} className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
        <p onClick={() => setShowRegVideo(true)} >
          How to Register
        </p>
      </div>
      <div className="login-form login-signin" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1" style={{ color: "white" }}>
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
          <p className="text-muted font-weight-bold">
            Select Your CAA Permit Number
          </p>
        </div>
        <Formik
          // enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values) => {
            onSubmit()
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Form className="form form-label-right">

                <div className="col-lg-12">
                  <Select name="bankaccounts" label=""
                    onChange={(e) => {
                      setCAANumber(e.target.value);
                    }}
                    required>
                    <option key="" value="">Select ....</option>
                    {unregisteredHatcheries &&
                      unregisteredHatcheries.map((details) => (
                        <option key={details.CAARegistrationNumber} value={details.CAARegistrationNumber}>
                          {`${details.name} - ${details.CAARegistrationNumber}`}
                        </option>
                      ))}
                  </Select>
                </div>

                <div className="form-group d-flex flex-wrap flex-center" style={{ marginTop: '5%' }}>
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    <span>Submit</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                  </button>

                  <Link to="/auth/login">
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </Form>
            </>
          )}
        </Formik>

      </div>

      <Modal
        show={showRegVideo}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>LEARN HOW TO REGISTER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/-cswy-7qQCk" title="YouTube video player"></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowRegVideo(false)} >Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
